import { put, all, takeLatest } from 'redux-saga/effects'

import { UserService } from '../../services/api'
import { signInSuccess, signInFailure } from './actions'
import { SIGN_IN } from '../constants'
import { notification } from 'antd'

export function* signIn(action) {
  try {
    const res = yield UserService.signIn(action.payload)
    if (res.message === 'Wrong data') {
      yield put(signInFailure())
      return notification.error({
        message: 'Error',
        description: 'User not found',
      })
    }
    if (res) {
      if (res.message === 'User not found') {
        yield put(signInFailure())
        return notification.error({
          message: 'Error',
          description: 'User not found',
        })
      } else {
        const { token } = res
        if (token) {
          localStorage.setItem('token', token)
        }
        yield put(signInSuccess(res))
        return notification.success({
          message: 'Logged in',
        })
      }
    } else {
      yield put(signInFailure())
      return notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
    }
  } catch (error) {
    yield put(signInFailure())
    return notification.error({
      message: 'Unexpected error',
      description: 'Something went wrong',
    })
  }
}

function* librarySagas() {
  yield all([takeLatest(SIGN_IN, signIn)])
}

export default librarySagas
