import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'

import rootReducer from './index-reducer'
import rootSaga from './index-sagas'

const sagaMiddleware = createSagaMiddleware()
// here after sagaMiddleware - logger
const middleware = [sagaMiddleware]
const enhancers = []

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, composedEnhancers)

sagaMiddleware.run(rootSaga)

export { store }
