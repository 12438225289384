import {
  GET_SLIDER_DATA,
  ADD_SLIDE,
  EDIT_SLIDE,
  DELETE_SLIDE,
  SUCCESS,
  FAILURE,
} from '../constants'

const initialState = {
  carouselSliderData: null,
  areSlidesLoading: false,
  isModalDisabled: false,
  shouldReload: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SLIDER_DATA:
      return {
        ...state,
        areSlidesLoading: true,
        shouldReload: false,
      }
    case GET_SLIDER_DATA + SUCCESS:
      return {
        ...state,
        carouselSliderData: action.carouselSliderData.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        }),
        areSlidesLoading: false,
      }
    case GET_SLIDER_DATA + FAILURE:
      return {
        ...state,
        areSlidesLoading: false,
      }
    // ADD_SLIDE
    case ADD_SLIDE:
      return {
        ...state,
        isModalDisabled: true,
      }
    case ADD_SLIDE + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case ADD_SLIDE + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    // EDIT_SLIDE
    case EDIT_SLIDE:
      return {
        ...state,
        isModalDisabled: true,
      }
    case EDIT_SLIDE + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case EDIT_SLIDE + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    // DELETE_SLIDE
    case DELETE_SLIDE:
      return {
        ...state,
        isModalDisabled: true,
      }
    case DELETE_SLIDE + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case DELETE_SLIDE + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }

    case 'RESET_CAROUSEL_STATE':
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
