import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input, Upload, Icon } from 'antd'

import { editTeamMember } from '../../../redux/team/actions'

import 'antd/dist/antd.css'

const EditTeamMemberModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  isModalDisabled,
  onEditTeamMember,
  modalData: {
    _id,
    caption,
    text,
    image,
    instagram,
    imageId,
    email,
    description,
  },
}) => {
  const [hasFile, setHasFile] = useState(false)
  const [isChangingImageProcess, setChangingImageProcess] = useState(false)

  const handleChange = file => {
    if (file && file.fileList.length >= 1) {
      setHasFile(true)
      return null
    }
    setHasFile(false)
  }

  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        onEditTeamMember({ values, imageId, _id, toggleModal })
      }
    })
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Edit member</h2>
        <Form.Item label="Name">
          {getFieldDecorator('caption', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 5, message: 'Minimum 2 symbols' },
            ],
            initialValue: caption,
          })(<Input size="default" type="text" placeholder="Name" />)}
        </Form.Item>
        <Form.Item label="Position">
          {getFieldDecorator('text', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 5, message: 'Minimum 2 symbols' },
            ],
            initialValue: text,
          })(<Input size="default" type="text" placeholder="Position" />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [{ min: 2, message: 'Minimum 2 symbols' }],
            initialValue: description,
          })(<Input size="default" type="text" placeholder="Description" />)}
        </Form.Item>
        {image && !isChangingImageProcess && (
          <>
            <a rel="noopener noreferrer" href={image} target="_blank">
              Image exists
            </a>
            <div style={{ margin: '0 0 32px 0' }}>
              <button
                className="action-button-edit-modal"
                onClick={() => setChangingImageProcess(true)}
              >
                Change image
              </button>
            </div>
          </>
        )}
        {isChangingImageProcess && (
          <Form.Item label="Image">
            {getFieldDecorator('image', {
              rules: [{ required: true, message: 'This field is required' }],
            })(
              <Upload
                beforeUpload={() => false}
                multiple={false}
                onChange={handleChange}
                accept="image/*"
              >
                <Button disabled={hasFile}>
                  <Icon type="upload" /> Click to upload
                </Button>
              </Upload>
            )}
            <button
              className="action-button-edit-modal"
              onClick={() => setChangingImageProcess(false)}
            >
              Reset
            </button>
          </Form.Item>
        )}
        <Form.Item label="Email">
          {getFieldDecorator('memberEmail', {
            rules: [
              {
                pattern: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/,
                message: 'Email is not valid. ',
              },
            ],
            initialValue: email,
          })(<Input size="default" type="email" placeholder="Email" />)}
        </Form.Item>
        <Form.Item label="Instagram">
          {getFieldDecorator('instagram', {
            initialValue: instagram,
          })(<Input size="default" type="text" placeholder="Only nickname" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

EditTeamMemberModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onEditTeamMember: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
  modalData: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  onEditTeamMember: action => dispatch(editTeamMember(action)),
})

export default connect(
  null,
  mapDispatchToProps
)(Form.create()(EditTeamMemberModal))
