import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input, Upload, Icon, notification } from 'antd'

import { onAddPartner } from '../../../redux/team/actions'

import 'antd/dist/antd.css'

const AddPartnerModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  onAddPartner,
  isModalDisabled,
}) => {
  const [file, setFile] = useState(null)
  const [hasFile, setHasFile] = useState(false)
  const onSubmit = () => {
    validateFields((error, values) => {
      if (!error) {
        if (file) {
          return onAddPartner({ values, toggleModal })
        } else {
          return notification.error({
            message: 'No file',
            description: `You've not attached any image`,
          })
        }
      }
    })
  }

  const handleChange = file => {
    if (file && file.fileList.length >= 1) {
      setFile(file.file)
      setHasFile(true)
      return null
    }
    setFile(null)
    setHasFile(false)
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Add partner</h2>
        <Form.Item label="Image">
          {getFieldDecorator('image', {
            rules: [{ required: true, message: 'This field is required' }],
          })(
            <Upload
              beforeUpload={() => false}
              multiple={false}
              onChange={handleChange}
              accept=".png, .jpg"
            >
              <Button disabled={hasFile}>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

AddPartnerModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onAddTeamMember: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

const mapDispatchToProps = dispatch => ({
  onAddPartner: action => dispatch(onAddPartner(action)),
})

export default connect(null, mapDispatchToProps)(Form.create()(AddPartnerModal))
