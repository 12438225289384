import { put, all, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { VideosService } from '../../services/api'

import {
  getVideosSuccess,
  getVideosFailure,
  getVideoSuccess,
  getVideoFailure,
  getCategoriesSuccess,
  editVideoFailure,
  editVideoSuccess,
  addVideoFailure,
  addVideoSuccess,
  deleteVideoSuccess,
  deleteVideoFailure,
  addCategorySuccess,
  addCategoryFailure,
  editCategorySuccess,
  editCategoryFailure,
  deleteCategorySuccess,
  deleteCategoryFailure,
  getMoreVideosSuccess,
  getMoreVideosFailure,
  getAllVideosSuccess,
  getAllVideosFailure,
  updateVideosListSuccess,
} from './actions'

import {
  GET_VIDEOS,
  GET_MORE_VIDEOS,
  GET_VIDEO,
  GET_CATEGORIES,
  ADD_VIDEO,
  EDIT_VIDEO,
  DELETE_VIDEO,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  GET_ALL_VIDEOS,
  UPDATE_VIDEOS_LIST,
} from '../constants'

const randomizeHeight = index => {
  // if (index === 0 || index % 4 === 0) {
  //   return 250
  // } else if (index === 1 || (index + 1) % 5 === 0) {
  //   return 280
  // } else if (index === 2 || index % 2 === 0) {
  //   return 310
  // } else {
  //   return 340
  // }
  // first and fourth element
  if (index === 0 || index % 4 === 0) {
    return 250
  } else if (index === 1 || (index + 1) % 5 === 0) {
    return 280
  } else if (index === 2 || index % 2 === 0) {
    return 310
  } else {
    return 280
  }
}

export function* fetchVideos(action) {
  try {
    const [videos, categories] = yield all([
      VideosService.getVideos(action.payload),
      VideosService.getCategories(),
    ])

    const mappedVideos = videos.data.map((video, index) => {
      const newHeight = randomizeHeight(index)

      video.newHeight = newHeight
      return video
    })
    // .sort((a, b) => {
    //   return b.date > a.date ? 1 : b.date < a.date ? -1 : 0
    // })

    yield put(
      getVideosSuccess({
        videos: mappedVideos,
        categories,
        totalPages: videos.pages,
      })
    )
  } catch (error) {
    yield put(getVideosFailure())
    console.error('Error')
  }
}

export function* fetchAllVideos() {
  try {
    const allVideos = yield VideosService.getAllVideos()

    yield put(
      getAllVideosSuccess({
        allVideos: allVideos.data,
      })
    )
  } catch (error) {
    yield put(getAllVideosFailure())
    console.error('Error')
  }
}

function* fetchMoreVideos(action) {
  try {
    const [videos] = yield all([VideosService.getVideos(action.payload)])

    const mappedVideos = videos.data.length
      ? videos.data.map((video, index) => {
          const newHeight = randomizeHeight(index)

          video.newHeight = newHeight
          return video
        })
      : // .sort((a, b) => {
        //   return b.date > a.date ? 1 : b.date < a.date ? -1 : 0
        // })
        []

    yield put(
      getMoreVideosSuccess({
        videos: mappedVideos,
        totalPages: videos.pages,
      })
    )
  } catch (err) {
    yield put(getMoreVideosFailure())
  }
}

export function* fetchVideo(action) {
  try {
    const video = yield VideosService.getVideo(action.payload)

    if (video && video._id) {
      yield put(getVideoSuccess(video))
    } else {
      yield put(getVideoFailure())
    }
  } catch (err) {
    yield put(getVideoFailure())
  }
}

export function* updateVideosList(action) {
  try {
    const res = yield VideosService.updateVideosList(action.payload)

    yield put(updateVideosListSuccess())
  } catch (err) {
    console.log('err: ', err)
    // yield put(getVideoFailure())
  }
}

export function* fetchCategories() {
  try {
    const categories = yield VideosService.getCategories()

    if (categories) {
      yield put(getCategoriesSuccess(categories))
    }
  } catch (err) {
    // yield put(getCategoriesFailure());
  }
}

export function* addVideo(action) {
  try {
    const res = yield VideosService.addVideo(action.payload)

    if (res.message === 'success') {
      notification.success({
        message: 'Added successfully',
      })
      yield put(addVideoSuccess())
      return action.payload.toggleModal()
    } else {
      if (res.status === 404) {
        notification.error({
          message: 'Unexpected error',
          description: 'Something went wrong || this video does not exist',
        })
      }
      yield put(addVideoFailure())
    }
  } catch (err) {
    yield put(addVideoFailure())
  }
}

export function* editVideo(action) {
  try {
    const res = yield VideosService.editVideo(action.payload)

    if (res.status === 200) {
      yield put(editVideoSuccess())
      notification.success({
        message: 'Edited successfully',
      })
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(editVideoFailure())
    }
  } catch (err) {
    yield put(editVideoFailure())
  }
}

export function* deleteVideo(action) {
  try {
    const res = yield VideosService.deleteVideo(action.payload)

    if (res.status === 200) {
      yield put(deleteVideoSuccess())
      notification.success({
        message: 'Deleted successfully',
      })
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(deleteVideoFailure())
    }
  } catch (err) {
    yield put(deleteVideoFailure())
  }
}

export function* addCategory(action) {
  try {
    const res = yield VideosService.addCategory(action.payload)

    if (res.gallery_category) {
      yield put(addCategorySuccess())
      notification.success({
        message: 'Added successfully',
      })
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(addCategoryFailure())
    }
  } catch (err) {
    yield put(addCategoryFailure())
  }
}

export function* editCategory(action) {
  try {
    const res = yield VideosService.editCategory(action.payload)

    if (res.status === 200) {
      yield put(editCategorySuccess())
      notification.success({
        message: 'Edited successfully',
      })
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(editCategoryFailure())
    }
  } catch (err) {
    yield put(editCategoryFailure())
  }
}

export function* deleteCategory(action) {
  try {
    const res = yield VideosService.deleteCategory(action.payload)

    if (res.status === 200) {
      yield put(deleteCategorySuccess())
      notification.success({
        message: 'Deleted successfully',
      })
      action.payload.setVideoFilter('')
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(deleteCategoryFailure())
    }
  } catch (err) {
    yield put(deleteCategoryFailure())
  }
}

function* librarySagas() {
  yield all([
    takeLatest(GET_VIDEOS, fetchVideos),
    takeLatest(GET_VIDEO, fetchVideo),
    takeLatest(GET_CATEGORIES, fetchCategories),
    takeLatest(GET_ALL_VIDEOS, fetchAllVideos),
    takeLatest(EDIT_VIDEO, editVideo),
    takeLatest(ADD_VIDEO, addVideo),
    takeLatest(DELETE_VIDEO, deleteVideo),
    takeLatest(ADD_CATEGORY, addCategory),
    takeLatest(EDIT_CATEGORY, editCategory),
    takeLatest(DELETE_CATEGORY, deleteCategory),
    takeLatest(GET_MORE_VIDEOS, fetchMoreVideos),
    takeLatest(UPDATE_VIDEOS_LIST, updateVideosList),
  ])
}

export default librarySagas
