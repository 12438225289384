import {
  GET_VIDEOS,
  GET_MORE_VIDEOS,
  GET_VIDEO,
  GET_CATEGORIES,
  SET_CHOOSED_VIDEO,
  EDIT_VIDEO,
  ADD_VIDEO,
  SUCCESS,
  FAILURE,
  DELETE_VIDEO,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_COPY_VIDEOS,
  GET_ALL_VIDEOS,
  UPDATE_VIDEOS_LIST,
} from '../constants'

const initialState = {
  videos: null,
  videosCopy: null,
  categories: null,
  databaseCategories: null,
  choosedVideoId: null,
  areVideosLoading: true,
  isModalDisabled: false,
  shouldReload: false,
  choosedSingleVideoId: null,
  isVideoLoading: false,
  shouldSingleVideoReload: false,
  singleVideo: null,
  totalPages: null,
  innerLoading: false,
  areAllVideosLoading: false,
  isUpdatingVideosList: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEOS:
      return {
        ...state,
        areVideosLoading: true,
        shouldReload: false,
      }
    case GET_VIDEOS + SUCCESS:
      return {
        ...state,
        videos: action.videos,
        //   .sort((a, b) => {
        //   return b.date > a.date ? 1 : b.date < a.date ? -1 : 0
        // }),
        videosCopy: action.videos,
        //   .sort((a, b) => {
        //   return b.date > a.date ? 1 : b.date < a.date ? -1 : 0
        // }),
        categories: action.categories || state.categories,
        areVideosLoading: false,
        totalPages: Number(action.totalPages),
      }
    case GET_VIDEOS + FAILURE:
      return {
        ...state,
        areVideosLoading: false,
      }

    case GET_ALL_VIDEOS: {
      return {
        ...state,
        areAllVideosLoading: true,
      }
    }
    case GET_ALL_VIDEOS + SUCCESS: {
      return {
        ...state,
        allVideos: action.payload.allVideos,
        areAllVideosLoading: false,
      }
    }
    case GET_ALL_VIDEOS + FAILURE: {
      return {
        ...state,
        allVideos: [],
        areAllVideosLoading: false,
      }
    }
    case GET_MORE_VIDEOS:
      return {
        ...state,
        innerLoading: true,
      }
    case GET_MORE_VIDEOS + SUCCESS:
      return {
        ...state,
        videos: [...state.videos, ...action.payload.videos],
        //   .sort((a, b) => {
        //   return b.date > a.date ? 1 : b.date < a.date ? -1 : 0
        // }),
        videosCopy: [...state.videos, ...action.payload.videos],
        // .sort((a, b) => {
        //   return b.date > a.date ? 1 : b.date < a.date ? -1 : 0
        // }),
        totalPages: Number(action.payload.totalPages),
        innerLoading: false,
      }
    case GET_MORE_VIDEOS + FAILURE:
      return {
        ...state,
        innerLoading: false,
      }
    case GET_VIDEO:
      return {
        ...state,
        isVideoLoading: true,
      }
    case GET_VIDEO + SUCCESS:
      return {
        ...state,
        isVideoLoading: false,
        singleVideo: action.video,
      }
    case GET_VIDEO + FAILURE:
      return {
        ...state,
        isVideoLoading: false,
      }
    case GET_CATEGORIES + SUCCESS:
      return {
        ...state,
        databaseCategories: action.categories,
      }
    case ADD_VIDEO:
      return {
        ...state,
        isModalDisabled: true,
      }
    case ADD_VIDEO + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case ADD_VIDEO + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case EDIT_VIDEO:
      return {
        ...state,
        isModalDisabled: true,
      }
    case EDIT_VIDEO + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case EDIT_VIDEO + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case DELETE_VIDEO:
      return {
        ...state,
        isModalDisabled: true,
      }
    case DELETE_VIDEO + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case DELETE_VIDEO + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case SET_CHOOSED_VIDEO:
      return {
        ...state,
        choosedVideoId: action.payload,
      }
    case ADD_CATEGORY:
      return {
        ...state,
        isModalDisabled: true,
      }
    case ADD_CATEGORY + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case ADD_CATEGORY + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case EDIT_CATEGORY:
      return {
        ...state,
        isModalDisabled: true,
      }
    case EDIT_CATEGORY + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case EDIT_CATEGORY + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case DELETE_CATEGORY:
      return {
        ...state,
        isModalDisabled: true,
      }
    case DELETE_CATEGORY + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case DELETE_CATEGORY + SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case 'RESET_VIDEOS_STATE':
      return {
        ...initialState,
      }

    case UPDATE_COPY_VIDEOS:
      return {
        ...state,
        allVideos: action.payload,
      }
    case UPDATE_VIDEOS_LIST:
      return {
        ...state,
        isUpdatingVideosList: true,
      }
    case UPDATE_VIDEOS_LIST + SUCCESS:
      return {
        ...state,
        isUpdatingVideosList: false,
        shouldReload: true,
      }
    default:
      return state
  }
}

export default reducer
