import {
  GET_TEAM_MEMBERS,
  SUCCESS,
  FAILURE,
  ADD_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  ADD_PARTNER,
  GET_PARTNERS,
  DELETE_PARTNER,
} from '../constants'

const initialState = {
  teamMembers: null,
  areTeamMembersLoading: true,
  shouldReload: false,
  isModalDisabled: false,
  isAddingPartner: false,
  shouldPartnersReload: false,
  partners: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAM_MEMBERS:
      return {
        ...state,
        areTeamMembersLoading: true,
        shouldReload: false,
      }
    case GET_TEAM_MEMBERS + SUCCESS:
      return {
        ...state,
        teamMembers: action.teamMembers,
        areTeamMembersLoading: false,
      }
    case GET_TEAM_MEMBERS + FAILURE:
      return {
        ...state,
        areTeamMembersLoading: true,
      }
    case ADD_TEAM_MEMBER:
      return {
        ...state,
        isModalDisabled: true,
      }
    case ADD_TEAM_MEMBER + SUCCESS:
      return {
        ...state,
        shouldReload: true,
        isModalDisabled: false,
      }
    case ADD_TEAM_MEMBER + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case EDIT_TEAM_MEMBER:
      return {
        ...state,
        isModalDisabled: true,
      }
    case EDIT_TEAM_MEMBER + SUCCESS:
      return {
        ...state,
        shouldReload: true,
        isModalDisabled: false,
      }
    case EDIT_TEAM_MEMBER + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case DELETE_TEAM_MEMBER:
      return {
        ...state,
        isModalDisabled: true,
      }
    case DELETE_TEAM_MEMBER + SUCCESS:
      return {
        ...state,
        shouldReload: true,
        isModalDisabled: false,
      }
    case DELETE_TEAM_MEMBER + FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    case 'RESET_MEMBERS_STATE':
      return {
        ...initialState,
      }
    case GET_PARTNERS: {
      return {
        ...state,
        shouldPartnersReload: false,
        isPartnersLoading: true,
      }
    }
    case GET_PARTNERS + SUCCESS: {
      return {
        ...state,
        isPartnersLoading: false,
        partners: action.payload,
      }
    }
    case GET_PARTNERS + FAILURE: {
      return {
        ...state,
        isPartnersLoading: false,
        partners: [],
      }
    }
    case ADD_PARTNER: {
      return {
        ...state,
        isAddingPartner: true,
      }
    }
    case ADD_PARTNER + SUCCESS: {
      return {
        ...state,
        isAddingPartner: false,
        shouldPartnersReload: true,
      }
    }
    case ADD_PARTNER + FAILURE: {
      return {
        ...state,
        isAddingPartner: false,
      }
    }
    case DELETE_PARTNER: {
      return {
        ...state,
        isDeletingPartner: true,
      }
    }
    case DELETE_PARTNER + SUCCESS: {
      return {
        ...state,
        isDeletingPartner: false,
        shouldPartnersReload: true,
      }
    }
    case DELETE_PARTNER + FAILURE: {
      return {
        ...state,
        isDeletingPartner: false,
      }
    }
    default:
      return state
  }
}

export default reducer
