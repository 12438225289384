import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Icon } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useMediaQuery } from 'beautiful-react-hooks'

import Loading from '../../Loading.jsx'
import EditVideoModal from './EditVideoModal'
import AddVideoModal from './AddVideoModal'
import ConfirmModal from '../../ConfirmModal'
import AddCategoryModal from './AddCategoryModal'
import EditCategoryModal from './EditCategoryModal'

import {
  getVideos,
  getMoreVideos,
  onGetAllVideos,
  resetState,
  setChoosedVideo,
  deleteVideo,
  deleteCategory,
  updateCopyVideos,
  onUpdateVideosList,
} from '../../../redux/videos/actions'

import './Videos.scss'

const VideoItem = ({ item, index }) => {
  const {
    caption,
    vimeo_response: { thumbnail_url },
    category,
  } = item

  return (
    <div className="video-item-wrapper">
      <div className="video-item">
        <div className="video-item-number">
          <span>{index + 1}</span>
        </div>
        <div className="video-item-name">
          <span>
            {caption} - {category.caption}
          </span>
        </div>
      </div>
    </div>
  )
}

const Videos = ({
  onGetVideos,
  onGetMoreVideos,
  onGetAllVideos,
  allVideos,
  areAllVideosLoading,
  onUpdateVideosList,
  onResetState,
  onSetChoosedVideo,
  videos,
  videosCopy,
  categories,
  areVideosLoading,
  isUserLoggedIn,
  isModalDisabled,
  shouldReload,
  onDeleteVideo,
  onDeleteCategory,
  totalPages,
  innerLoading,
  updateCopyVideos,
  isUpdatingVideosList,
}) => {
  const [videoFilter, setVideoFilter] = useState('')
  const [page, setPage] = useState(1)
  const [isModalShown, setModalStatus] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalData, setModalData] = useState({})
  const [isSortingVideos, setSortingVideos] = useState(false)
  const [sortedVideos, setSortedVideos] = useState(videos)

  const history = useHistory()

  const isMobile = useMediaQuery('(max-width: 768px)')

  const toggleModal = (e = null, type = '', data = {}) => {
    if (e) e.stopPropagation()
    setModalStatus(!isModalShown)
    setModalType(type)
    setModalData(data)
  }

  const watchVideo = video => {
    const { _id } = video

    // onSetChoosedVideo(_id)
    history.push(`/videos/watch/${_id}`, { backroute: '/videos' })
  }

  const renderFilters = () =>
    categories && categories.length
      ? categories.map(category => {
          return category.count_items || isUserLoggedIn ? (
            <li
              className={videoFilter === category._id ? 'active' : null}
              key={category._id}
            >
              <span
                onClick={() => {
                  setPage(1)
                  setVideoFilter(category._id)
                }}
              >
                {`${category.caption} ${
                  isUserLoggedIn ? ` - ${category.count_items}` : ''
                }`}
              </span>
              {isUserLoggedIn ? (
                <div className="actions">
                  <Icon
                    onClick={e => toggleModal(e, 'editCategory', category)}
                    className="edit"
                    type="form"
                  />
                  <Icon
                    onClick={e => toggleModal(e, 'deleteCategory', category)}
                    className="delete"
                    type="close"
                  />
                </div>
              ) : null}
            </li>
          ) : null
        })
      : null

  const renderVideos = order =>
    videos.map((video, index) => {
      const {
        vimeo_response: { thumbnail_url, author_name },
        caption,
        production,
        image,
        _id,
        newHeight,
      } = video

      if (order === 'odd') {
        if ((index + 1) % 2 === 1) {
          return (
            <li
              key={_id}
              style={{ height: newHeight }}
              onClick={() => watchVideo(video)}
            >
              <img
                src={image || thumbnail_url}
                alt={caption}
                className="thumbnail"
              />
              <div className="description">
                <span className="description-title">{caption}</span>
                <span className="description-author-name">
                  {production || author_name}
                </span>
                {isUserLoggedIn ? (
                  <div className="actions">
                    <Icon
                      onClick={e => toggleModal(e, 'editVideo', video)}
                      className="edit"
                      type="form"
                    />
                    <Icon
                      onClick={e => toggleModal(e, 'deleteVideo', video)}
                      className="delete"
                      type="close"
                    />
                  </div>
                ) : null}
              </div>
            </li>
          )
        }
      } else if (order === 'even') {
        if ((index + 1) % 2 === 0) {
          return (
            <li
              key={_id}
              style={{ height: newHeight }}
              onClick={() => watchVideo(video)}
            >
              <img
                src={image || thumbnail_url}
                alt={caption}
                className="thumbnail"
              />
              <div className="description">
                <span className="description-title">{caption}</span>
                <span className="description-author-name">
                  {production || author_name}
                </span>
                {isUserLoggedIn ? (
                  <div className="actions">
                    <Icon
                      onClick={e => toggleModal(e, 'editVideo', video)}
                      className="edit"
                      type="form"
                    />
                    <Icon
                      onClick={e => toggleModal(e, 'deleteVideo', video)}
                      className="delete"
                      type="close"
                    />
                  </div>
                ) : null}
              </div>
            </li>
          )
        }
      }
      return null
    })

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',

    background: isDragging ? '#46bffc' : '',
    borderBottom: '4px solid #46bffc',

    ...draggableStyle,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      allVideos,
      result.source.index,
      result.destination.index
    )

    return updateCopyVideos(items)
  }

  useEffect(() => {
    onGetVideos({ videoFilter, page })
  }, [videoFilter, onGetVideos])

  useEffect(() => {
    if (shouldReload) {
      onGetVideos({ videoFilter, page })
    }
  }, [shouldReload, onGetVideos, videoFilter, page])

  useEffect(() => {
    if (isUserLoggedIn) {
      onGetAllVideos()
    }
  }, [isUserLoggedIn])

  useEffect(() => {
    if (isSortingVideos) {
      onGetAllVideos()
    }
  }, [isSortingVideos])

  useEffect(() => {
    setSortingVideos(false)
  }, [shouldReload])

  if (areVideosLoading) {
    return (
      <div className="videos-wrapper">
        <div className={`videos ${videos?.length ? '' : 'empty'}`}>
          <Loading />
        </div>
      </div>
    )
  }

  return (
    <div className="videos-wrapper">
      <div className={`videos ${videos?.length ? '' : 'empty'}`}>
        {isSortingVideos && videoFilter === '' ? (
          <>
            {areAllVideosLoading ? (
              <p>Loading</p>
            ) : (
              <>
                {allVideos ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {allVideos &&
                            allVideos.map((item, index) => (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <VideoItem item={item} index={index} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : null}

                <div className="sort-videos-actions">
                  {isUpdatingVideosList ? null : (
                    <>
                      <div
                        className="btn btn-secondary"
                        onClick={() => {
                          window.scrollTo(0, 0)
                          updateCopyVideos(videos)
                          setSortingVideos(false)
                        }}
                      >
                        <span>cancel</span>
                      </div>
                      <div
                        className="btn btn-primary"
                        onClick={() => onUpdateVideosList({ allVideos })}
                      >
                        <span>save</span>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <ul className="videos-filters">
              <li
                className={videoFilter === '' ? 'active' : ''}
                onClick={() => {
                  setPage(1)
                  setVideoFilter('')
                }}
              >
                <span>All</span>
              </li>
              {renderFilters()}
            </ul>
            {isUserLoggedIn && (
              <div className="new">
                <div
                  className="new-video"
                  style={{ height: 100 }}
                  onClick={e => toggleModal(e, 'addVideo')}
                >
                  <div className="add-new">
                    <span className="title">add video</span>
                  </div>
                </div>
                <div
                  className="new-category"
                  style={{ height: 100 }}
                  onClick={e => toggleModal(e, 'addCategory')}
                >
                  <div className="add-new">
                    <span className="title">add category</span>
                  </div>
                </div>
                {videoFilter === '' ? (
                  <div
                    className="sort-button"
                    style={{ height: 100 }}
                    onClick={e => {
                      window.scrollTo(0, 0)
                      setSortingVideos(true)
                    }}
                  >
                    <div className="sort-videos">
                      <span className="title">sort videos</span>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            {videos?.length && (
              <>
                <div className="videos-items">
                  {isMobile ? (
                    <ul className="left">
                      {videos.map((video, index) => {
                        const {
                          vimeo_response: { thumbnail_url, author_name },
                          caption,
                          production,
                          image,
                          _id,
                          newHeight,
                        } = video

                        return (
                          <li
                            key={_id}
                            style={{ height: newHeight }}
                            onClick={() => watchVideo(video)}
                          >
                            <img
                              src={image || thumbnail_url}
                              alt={caption}
                              className="thumbnail"
                            />
                            <div className="description">
                              <span className="description-title">
                                {caption}
                              </span>
                              <span className="description-author-name">
                                {production || author_name}
                              </span>
                              {isUserLoggedIn ? (
                                <div className="actions">
                                  <Icon
                                    onClick={e =>
                                      toggleModal(e, 'editVideo', video)
                                    }
                                    className="edit"
                                    type="form"
                                  />
                                  <Icon
                                    onClick={e =>
                                      toggleModal(e, 'deleteVideo', video)
                                    }
                                    className="delete"
                                    type="close"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <>
                      <ul className="left">{renderVideos('odd')}</ul>
                      <ul className="right">{renderVideos('even')}</ul>
                    </>
                  )}
                </div>
                {page < totalPages && !innerLoading && (
                  <div className="load-more-block">
                    <span
                      onClick={() => {
                        setPage(page + 1)
                        onGetMoreVideos({ videoFilter, page: page + 1 })
                      }}
                    >
                      Load more
                    </span>
                  </div>
                )}
                {innerLoading && (
                  <div className="load-more-block">
                    <span>Loading...</span>
                  </div>
                )}
              </>
            )}
            {videos?.length === 0 && (
              <div className="empty-block">
                <p className="no-videos">No videos</p>
              </div>
            )}
          </>
        )}
      </div>
      {isModalShown && modalType === 'addVideo' && (
        <AddVideoModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          categories={categories}
          isModalDisabled={isModalDisabled}
        />
      )}
      {isModalShown && modalType === 'editVideo' && (
        <EditVideoModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          modalData={modalData}
          categories={categories}
          isModalDisabled={isModalDisabled}
        />
      )}
      {isModalShown && modalType === 'deleteVideo' && (
        <ConfirmModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          modalData={modalData}
          isModalDisabled={isModalDisabled}
          onDelete={onDeleteVideo}
        />
      )}
      {isModalShown && modalType === 'addCategory' && (
        <AddCategoryModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          isModalDisabled={isModalDisabled}
        />
      )}
      {isModalShown && modalType === 'editCategory' && (
        <EditCategoryModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          modalData={modalData}
          isModalDisabled={isModalDisabled}
        />
      )}
      {isModalShown && modalType === 'deleteCategory' && (
        <ConfirmModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          modalData={modalData}
          isModalDisabled={isModalDisabled}
          onDelete={onDeleteCategory}
          setVideoFilter={setVideoFilter}
        />
      )}
    </div>
  )
}

Videos.propTypes = {
  onGetVideos: PropTypes.func.isRequired,
  onResetState: PropTypes.func.isRequired,
  onSetChoosedVideo: PropTypes.func.isRequired,
  videos: PropTypes.array,
  videosCopy: PropTypes.array,
  categories: PropTypes.array,
  databaseCategories: PropTypes.array,
  areVideosLoading: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
  shouldReload: PropTypes.bool.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
}

Videos.defaultProps = {
  videos: [],
  videosCopy: [],
  areVideosLoading: true,
  categories: [],
  databaseCategories: [],
}

const mapStateToProps = state => ({
  videos: state.videos.videos,
  videosCopy: state.videos.videosCopy,
  allVideos: state.videos.allVideos,
  areAllVideosLoading: state.videos.areAllVideosLoading,
  categories: state.videos.categories,
  databaseCategories: state.videos.databaseCategories,
  areVideosLoading: state.videos.areVideosLoading,
  isUserLoggedIn: state.user.isUserLoggedIn,
  isModalDisabled: state.videos.isModalDisabled,
  shouldReload: state.videos.shouldReload,
  totalPages: state.videos.totalPages,
  innerLoading: state.videos.innerLoading,
  isUpdatingVideosList: state.videos.isUpdatingVideosList,
})

const mapDispatchToProps = dispatch => ({
  onGetVideos: action => dispatch(getVideos(action)),
  onGetMoreVideos: action => dispatch(getMoreVideos(action)),
  onGetAllVideos: () => dispatch(onGetAllVideos()),
  onResetState: () => dispatch(resetState()),
  onSetChoosedVideo: action => dispatch(setChoosedVideo(action)),
  onDeleteVideo: action => dispatch(deleteVideo(action)),
  onDeleteCategory: action => dispatch(deleteCategory(action)),
  updateCopyVideos: action => dispatch(updateCopyVideos(action)),
  onUpdateVideosList: action => dispatch(onUpdateVideosList(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Videos)
