import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { NavBar, Footer } from '../../components'

const MainLayout = ({
  toggleTheme,
  shouldFooterBeVisible,
  isTransparent,
  ...props
}) => {
  return (
    <>
      <NavBar toggleTheme={toggleTheme} />
      <Route {...props} />
      {shouldFooterBeVisible && <Footer />}
    </>
  )
}

MainLayout.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
  shouldFooterBeVisible: PropTypes.bool,
  isTransparent: PropTypes.bool,
}

MainLayout.defaultProps = {
  shouldFooterBeVisible: true,
  isTransparent: false,
}

export default MainLayout
