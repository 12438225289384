import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class PhotosGalleryService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }

  getPhotos({ galleryFilter, page }) {
    return this.request.get(`/api/photo?category=${galleryFilter}&page=${page}`)
  }

  getCategories() {
    return this.request.get('/api/gallery_category/all/photo')
  }

  addPhoto({
    values: {
      caption,
      photo: { file },
      photoCategory,
      text,
    },
  }) {
    const formData = new FormData()
    if (caption) {
      formData.append('caption', caption)
    }
    if (photoCategory) {
      formData.append('category', photoCategory)
    }
    if (text) {
      formData.append('text', text)
    }
    formData.append('image', file)
    return this.request.post('/api/photo/add', formData, true)
  }

  editPhoto({
    values: { caption, photoCategory, text },
    modalData: { _id, imageId },
  }) {
    const formData = new FormData()
    if (caption) {
      formData.append('caption', caption)
    }
    if (text) {
      formData.append('text', text)
    }
    if (photoCategory && photoCategory !== 'Choose some category') {
      formData.append('category', photoCategory)
    }
    return this.request.post(`/api/photo/update/${_id}`, formData, true)
  }

  deletePhoto({ modalData: { imageId, _id } }) {
    return this.request.get(`/api/photo/remove/${_id}`)
  }

  addCategory({ values: { caption } }) {
    return this.request.post(
      `/api/gallery_category/add`,
      JSON.stringify({ caption, type: 'photo' })
    )
  }

  editCategory({ values: { caption }, _id }) {
    return this.request.post(
      `/api/gallery_category/update/${_id}`,
      JSON.stringify({ caption, type: 'photo' })
    )
  }

  deleteCategory({ modalData: { _id } }) {
    return this.request.get(`/api/gallery_category/remove/${_id}`)
  }
}

export default new PhotosGalleryService()
