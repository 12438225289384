import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input, Upload, Icon, notification } from 'antd'

import { addTeamMember } from '../../../redux/team/actions'

import 'antd/dist/antd.css'

const AddTeamMemberModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  onAddTeamMember,
  isModalDisabled,
}) => {
  const [file, setFile] = useState(null)
  const [hasFile, setHasFile] = useState(false)
  const onSubmit = () => {
    validateFields((error, values) => {
      if (!error) {
        if (file) {
          return onAddTeamMember({ values, toggleModal })
        } else {
          return notification.error({
            message: 'No file',
            description: `You've not attached any image`,
          })
        }
      }
    })
  }

  const handleChange = file => {
    if (file && file.fileList.length >= 1) {
      setFile(file.file)
      setHasFile(true)
      return null
    }
    setFile(null)
    setHasFile(false)
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Add member</h2>
        <Form.Item label="Name">
          {getFieldDecorator('caption', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 2, message: 'Minimum 2 symbols' },
            ],
          })(<Input size="default" type="text" placeholder="Name" />)}
        </Form.Item>
        <Form.Item label="Position">
          {getFieldDecorator('text', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 2, message: 'Minimum 2 symbols' },
            ],
          })(
            <Input
              maxLength={350}
              size="default"
              type="text"
              placeholder="Position"
            />
          )}
        </Form.Item>
        <Form.Item label="Image">
          {getFieldDecorator('image', {
            rules: [{ required: true, message: 'This field is required' }],
          })(
            <Upload
              beforeUpload={() => false}
              multiple={false}
              onChange={handleChange}
              accept=".png, .jpg"
            >
              <Button disabled={hasFile}>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('memberEmail', {
            rules: [
              {
                pattern: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/,
                message: 'Email is not valid. ',
              },
            ],
          })(<Input size="default" type="email" placeholder="Email" />)}
        </Form.Item>
        <Form.Item label="Instagram">
          {getFieldDecorator('instagram')(
            <Input size="default" type="text" placeholder="Only nickname" />
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

AddTeamMemberModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onAddTeamMember: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

const mapDispatchToProps = dispatch => ({
  onAddTeamMember: action => dispatch(addTeamMember(action)),
})

export default connect(
  null,
  mapDispatchToProps
)(Form.create()(AddTeamMemberModal))
