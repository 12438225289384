import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Form, Input, Select } from 'antd'

import 'antd/dist/antd.css'

const EditPhotoModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  onEditPhoto,
  isModalDisabled,
  photosCategories,
  modalData,
}) => {
  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        onEditPhoto({ values, toggleModal, modalData })
      }
    })
  }

  const parseCategories = () =>
    photosCategories.map(category => {
      const { caption, _id } = category
      return (
        <Select.Option key={_id} value={_id}>
          {caption}
        </Select.Option>
      )
    })

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Edit photo</h2>
        <Form.Item label="Caption">
          {getFieldDecorator('caption', { initialValue: modalData.caption })(
            <Input size="default" type="text" placeholder="Caption" />
          )}
        </Form.Item>
        <Form.Item label="Text">
          {getFieldDecorator('text', { initialValue: modalData.text })(
            <Input size="default" type="text" placeholder="Text" />
          )}
        </Form.Item>
        <Form.Item label="Photo category">
          {getFieldDecorator('photoCategory', {
            initialValue: modalData.category._id || 'Choose some category',
          })(
            <Select placeholder="Photo category" size="default">
              {photosCategories && parseCategories()}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

EditPhotoModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onEditPhoto: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

export default Form.create()(EditPhotoModal)
