import { all } from 'redux-saga/effects'

import UserSagas from '../redux/user/saga'
import CarouselSliderSagas from '../redux/carousel/saga'
import VideosSagas from '../redux/videos/saga'
import TeamSagas from '../redux/team/saga'
import { GallerySagas } from '../redux/gallery/gallery'

export default function* rootSaga() {
  yield all([
    UserSagas(),
    CarouselSliderSagas(),
    VideosSagas(),
    TeamSagas(),
    GallerySagas(),
  ])
}
