import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class TeamService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }

  getTeamMembers() {
    return this.request.get('/api/team')
  }

  addTeamMember({
    values: {
      caption,
      text,
      image: { file },
      instagram,
      memberEmail,
    },
  }) {
    const formData = new FormData()
    formData.append('caption', caption)
    formData.append('text', text)
    formData.append('image', file)
    if (instagram) {
      formData.append('instagram', instagram)
    }
    if (memberEmail) {
      formData.append('email', memberEmail)
    }
    return this.request.post('/api/team/add', formData, true)
  }

  editTeamMember({
    values: { caption, text, image, instagram, memberEmail, description },
    _id,
    imageId,
  }) {
    const formData = new FormData()
    formData.append('caption', caption)
    formData.append('text', text)
    if (image && image.file) {
      formData.append('image', image.file)
    }
    if (instagram) {
      formData.append('instagram', instagram)
    }
    if (memberEmail) {
      formData.append('email', memberEmail)
    }
    if (description) {
      formData.append('description', description)
    }
    return this.request.post(`/api/team/update/${_id}`, formData, true)
  }

  deleteTeamMember({ modalData: { _id, imageId } }) {
    return this.request.get(`/api/team/remove/${_id}`)
  }

  getPartners() {
    return this.request.get('/api/logo/')
  }

  addPartner({
    values: {
      image: { file },
    },
  }) {
    const formData = new FormData()

    formData.append('image', file)
    return this.request.post('/api/logo/add', formData, true)
  }

  deletePartner({ modalData }) {
    return this.request.get(`/api/logo/remove/${modalData}`)
  }

  getTexts() {
    return this.request.get('/api/about_us')
  }

  updateTexts({ text, text_extra }) {
    return this.request.post(
      '/api/about_us/update',
      JSON.stringify({ text, text_extra })
    )
  }
}

export default new TeamService()
