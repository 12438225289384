import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Icon } from 'antd'
import { Carousel } from 'react-responsive-carousel'
import Vimeo from '@u-wave/react-vimeo'

import './VideosCarousel.scss'

const VideosCarousel = ({ videos, choosedVideoId }) => {
  const history = useHistory()
  const remapVideos = () => {
    if (!videos) {
      return null
    }

    let indexToShift = null
    const shiftedValues = []

    const copyVideos = videos.map(video => video)

    videos.map((video, index) => {
      if (video._id === choosedVideoId) {
        indexToShift = index
        return null
      }
      return null
    })
    for (let i = 0; i < videos.length - indexToShift; i++) {
      shiftedValues.unshift(copyVideos.pop())
    }

    const newArray = [...shiftedValues, ...copyVideos]

    return newArray
  }

  const renderCarousel = () =>
    remapVideos().map(newVideo => {
      const {
        vimeo_response: { author_name },
        caption,
        production,
        director,
        pop: dop,
        url,
        _id,
      } = newVideo
      return (
        <div key={_id} className="video">
          <Vimeo
            autopause
            video={url}
            height={600}
            width={920}
            className="vimeo"
          />
          <div className="video-description">
            <div className="main-info">
              <h1>{caption}</h1>
              <p>{production || author_name}</p>
            </div>
            {director || dop || production ? (
              <div className="video-additional-description">
                {director && (
                  <span className="main">
                    Director: <span>{director || 'No data'}</span>
                  </span>
                )}
                {dop && (
                  <span className="main">
                    DOP: <span>{dop}</span>
                  </span>
                )}
                {production && (
                  <span className="main">
                    Production company: <span>{production}</span>
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </div>
      )
    })

  const goBack = () => history.goBack()

  return !videos ? (
    <Redirect to="/videos" />
  ) : (
    <div className="videos-carousel-wrapper">
      <span className="goBack" onClick={goBack}>
        <Icon type="close" style={{ fontSize: 35 }} />
      </span>
      <div className="videos-carousel">
        <Carousel
          showThumbs={false}
          showIndicators={false}
          swipeable={false}
          showStatus={false}
          infiniteLoop
        >
          {renderCarousel()}
        </Carousel>
      </div>
    </div>
  )
}

VideosCarousel.propTypes = {
  videos: PropTypes.array,
  choosedVideoId: PropTypes.string,
}

VideosCarousel.defaultProps = {
  videos: [],
  choosedVideoId: '',
}

const mapStateToProps = state => ({
  videos: state.videos.videos,
  choosedVideoId: state.videos.choosedVideoId,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(VideosCarousel)
