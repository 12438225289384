import React from 'react'
import { useHistory } from 'react-router-dom'

import './FollowUs.scss'

const FollowUs = () => {
  const link = window.location.pathname
  const history = useHistory()

  const navigateToContacts = e => {
    e.stopPropagation()

    history.push('/contacts')
  }

  return (
    <div
      className="follow_us"
      style={{
        backgroundColor:
          link === '/' ||
          link === '/Ukraine' ||
          link === '/Kazakhstan' ||
          link === '/Georgia'
            ? 'white'
            : null,
      }}
      onClick={e => navigateToContacts(e)}
    >
      <p
        className="follow-us-text"
        style={{
          color:
            link === '/' ||
            link === '/Ukraine' ||
            link === '/Kazakhstan' ||
            link === '/Georgia'
              ? 'black'
              : null,
        }}
      >
        contacts
      </p>
      <ul className="links">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/2332film"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="15"
              viewBox="0 0 8 15"
            >
              <path
                style={{
                  fill:
                    link === '/' ||
                    link === '/Ukraine' ||
                    link === '/Kazakhstan' ||
                    link === '/Georgia'
                      ? 'black'
                      : null,
                }}
                className="follow"
                d="M2.094 2.9v2.062H.6v2.522h1.494v7.492h3.069V7.484h2.06s.192-1.21.286-2.531H5.175V3.229c0-.258.334-.604.665-.604h1.672V0H5.239c-3.22 0-3.145 2.524-3.145 2.9z"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vimeo.com/2332film"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="15"
              viewBox="0 0 18 15"
            >
              <path
                style={{
                  fill: '#46bffc',
                }}
                d="M15.207.204c-2.34-.085-3.92 1.256-4.753 4.002.429-.192.846-.278 1.249-.278.857 0 1.236.49 1.134 1.464-.05.59-.427 1.447-1.133 2.575-.709 1.128-1.239 1.693-1.592 1.693-1.07 0-1.713-5.12-1.93-6.517C7.879 1.199 7.07.29 5.759.417 4.554.532 2.452 2.701.8 4.172l.794 1.034c.755-.535 1.196-.804 1.322-.804 1.315 0 1.952 4.475 2.973 7.803.685 1.837 1.507 2.756 2.497 2.756 1.591 0 3.53-1.507 5.827-4.523 2.217-2.886 3.366-5.162 3.44-6.821l.009-.007c.094-2.225-.718-3.363-2.455-3.406z"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/2332film/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path
                style={{
                  fill:
                    link === '/' ||
                    link === '/Ukraine' ||
                    link === '/Kazakhstan' ||
                    link === '/Georgia'
                      ? 'black'
                      : null,
                }}
                className="follow"
                d="M13.382.6H2.794C1.584.6.6 1.584.6 2.794v10.588c0 1.21.984 2.194 2.194 2.194h10.588c1.21 0 2.194-.984 2.194-2.194V2.794c0-1.21-.984-2.194-2.194-2.194zM8.117 12.007c-2.177 0-3.949-1.77-3.949-3.948S5.94 4.11 8.118 4.11c2.177 0 3.948 1.771 3.948 3.949 0 2.177-1.771 3.948-3.949 3.948zm4.388-7.02c-.726 0-1.317-.59-1.317-1.316 0-.726.591-1.316 1.317-1.316s1.316.59 1.316 1.316c0 .726-.59 1.317-1.316 1.317z"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default FollowUs
