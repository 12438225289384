import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input } from 'antd'

import { addCategory } from '../../../redux/videos/actions'

import 'antd/dist/antd.css'

const AddCategoryModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  onAddCategory,
  isModalDisabled,
  onAdd = null,
}) => {
  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        if (onAdd) {
          onAdd({ values, toggleModal })
        } else {
          onAddCategory({ values, toggleModal })
        }
      }
    })
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Add category</h2>
        <Form.Item label="Caption">
          {getFieldDecorator('caption', {
            rules: [{ required: true, message: 'This field is required' }],
          })(<Input size="default" type="text" placeholder="Caption" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  onAddCategory: action => dispatch(addCategory(action)),
})

AddCategoryModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onAddCategory: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

export default connect(
  null,
  mapDispatchToProps
)(Form.create()(AddCategoryModal))
