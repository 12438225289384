import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import Map from './GoogleMap'

import './Contacts.scss'

const Contacts = ({ theme }) => {
  return (
    <div className="contacts-wrapper">
      <div className="contacts">
        <div className="contacts-description">
          {/* <p className="title">shooting in:</p>
          <NavLink to="contacts/shooting-in/Ukraine" className="country">
            Ukraine
          </NavLink>
          <NavLink to="contacts/shooting-in/Georgia" className="country">
            Georgia
          </NavLink>
          <NavLink to="contacts/shooting-in/Kazakhstan" className="country">
            Kazakhstan
          </NavLink> */}
          <div className="section">
            <p className="title">bid requests</p>
            <p className="description">
              <a href="mailto:bids@2332.film">bids@2332.film</a>
            </p>
          </div>
          <div className="section">
            <p className="title">general questions</p>
            <p className="description">
              <a href="mailto:info@2332.film">info@2332.film</a>
            </p>
          </div>
          <div className="section">
            <p className="title">apply cv</p>
            <p className="description">
              <a href="mailto:cv@2332.film">cv@2332.film</a>
            </p>
          </div>
          <div className="section">
            <p className="title">phone</p>
            <p className="description">
              <a href="tel:+38 044 425 9624">+38 044 425 9624</a>
            </p>
          </div>
          <div className="section">
            <p className="title">location</p>
            <p className="description">
              Kiev, Ukraine <br></br>Pochayninskaya str. 25/49, <br></br> office
              63, 9 floor
            </p>
          </div>
        </div>
      </div>
      <div className="map">
        <Map
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBndLHTxfUBaQjwW4TXF096s5SFeXBC058&v=3.exp&libraries=geometry,drawing,places&language=en"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className="map-inner" />}
          mapElement={<div style={{ height: `100%` }} />}
          theme="dark"
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  theme: state.user.theme,
})

export default connect(mapStateToProps)(Contacts)
