import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon } from 'antd'
import { Carousel } from 'react-responsive-carousel'

import AddPhotoModal from './AddPhotoModal'
import EditPhotoModal from './EditPhotoModal'
import AddCategoryModal from '../videos/AddCategoryModal'
import EditCategoryModal from '../videos/EditCategoryModal'
import ConfirmModal from '../../ConfirmModal'
import Loading from '../../Loading'

import {
  getPhotos,
  getMorePhotos,
  addPhoto,
  editPhoto,
  deletePhoto,
  resetState,
  addCategory,
  editCategory,
  deleteCategory,
  setGalleryFilter,
} from '../../../redux/gallery/gallery'

import './Gallery.scss'

const Gallery = ({
  isUserLoggedIn,
  arePhotosLoading,
  photos,
  onGetPhotos,
  onGetMorePhotos,
  onAddPhoto,
  onEditPhoto,
  onDeletePhoto,
  isModalDisabled,
  photosCategories,
  shouldReload,
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  totalPages,
  innerLoading,
  galleryFilter,
  onSetGalleryFilter,
}) => {
  const [choosedPhoto, setChoosedPhoto] = useState(null)
  const [page, setPage] = useState(1)
  const [isModalShown, setModalStatus] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalData, setModalData] = useState({})
  const [isCarouselOpened, setCarouselState] = useState(false)

  const body = document.getElementsByTagName('body')[0]

  const toggleMenu = () => {
    setCarouselState(!isCarouselOpened)
    if (!isCarouselOpened) {
      body.style.position = 'fixed'
    } else {
      body.style.position = 'unset'
    }
  }

  const stopWatching = e => {
    e.stopPropagation()
    setChoosedPhoto(null)
    toggleMenu()
  }

  const remapPhotos = () => {
    if (!photos) {
      return null
    }

    let indexToShift = null
    const shiftedValues = []

    const copyPhotos = photos.map(member => member)

    photos.map((photo, index) => {
      if (photo._id === choosedPhoto._id) {
        indexToShift = index
        return null
      }
      return null
    })
    for (let i = 0; i < photos.length - indexToShift; i++) {
      shiftedValues.unshift(copyPhotos.pop())
    }

    const newArray = [...shiftedValues, ...copyPhotos]

    return newArray
  }

  const renderCarousel = () => {
    const photos = remapPhotos()
    return photos.map(newPhoto => {
      const { _id, caption, text, image } = newPhoto
      return (
        <div key={_id} className="carousel-member">
          <div className="inner">
            <img src={image} alt={caption} />
            {(caption || text) && (
              <div className="caption">
                <h2>{caption}</h2>
                <h4>{text}</h4>
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  const toggleModal = (e = null, type = '', data = {}) => {
    if (e) e.stopPropagation()
    setModalStatus(!isModalShown)
    setModalType(type)
    setModalData(data)
  }

  const renderFilters = () =>
    photosCategories && photosCategories.length
      ? photosCategories.map(category => {
          return category.count_items > 0 || isUserLoggedIn ? (
            <li
              className={galleryFilter === category._id ? 'active' : null}
              key={category._id}
            >
              <span
                onClick={() => {
                  onSetGalleryFilter(category._id)
                  setPage(1)
                }}
              >
                {`${category.caption} ${
                  isUserLoggedIn ? `- ${category.count_items}` : ''
                }`}
              </span>
              {isUserLoggedIn ? (
                <>
                  {category.caption === 'Ukraine' ? null : (
                    <div className="actions">
                      <Icon
                        onClick={e => toggleModal(e, 'editCategory', category)}
                        className="edit"
                        type="form"
                      />
                      <Icon
                        onClick={e =>
                          toggleModal(e, 'deleteCategory', category)
                        }
                        className="delete"
                        type="close"
                      />
                    </div>
                  )}
                </>
              ) : null}
            </li>
          ) : null
        })
      : null

  const renderPhotos = () =>
    photos && photos !== undefined
      ? photos.map(photo => {
          const { image, _id, caption, text } = photo
          return (
            <li
              key={_id}
              onClick={() => {
                setChoosedPhoto(photo)
                toggleMenu()
              }}
            >
              <img src={image} alt={caption} className="thumbnail" />
              <div className="description">
                <span className="description-title">{caption}</span>
                <span className="description-text">{text}</span>
                {isUserLoggedIn ? (
                  <div className="actions">
                    <Icon
                      onClick={e => toggleModal(e, 'editPhoto', photo)}
                      className="edit"
                      type="form"
                    />
                    <Icon
                      onClick={e => toggleModal(e, 'deletePhoto', photo)}
                      className="delete"
                      type="close"
                    />
                  </div>
                ) : null}
              </div>
            </li>
          )
        })
      : null

  useEffect(() => {
    if (shouldReload) {
      onGetPhotos({ galleryFilter, page })
    }
  }, [onGetPhotos, shouldReload, page, galleryFilter])

  useEffect(() => {
    onGetPhotos({ galleryFilter, page })
  }, [galleryFilter, onGetPhotos])

  return (
    <div className={`gallery-wrapper ${isCarouselOpened ? 'opened' : ''}`}>
      <div className={`photos ${photos && photos.length > 0 ? '' : 'empty'}`}>
        {arePhotosLoading ? (
          <Loading />
        ) : (
          <>
            {choosedPhoto ? (
              <>
                <div className="team-members-carousel">
                  <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    swipeable={false}
                    showStatus={false}
                    showArrows={false}
                    infiniteLoop
                  >
                    {renderCarousel()}
                  </Carousel>
                  <Icon
                    onClick={e => stopWatching(e)}
                    className="stop"
                    type="close"
                  />
                </div>
              </>
            ) : (
              <>
                <ul className="photos-filters">
                  <li
                    className={galleryFilter === '' ? 'active' : ''}
                    onClick={() => {
                      onSetGalleryFilter('')
                      setPage(1)
                    }}
                  >
                    <span>All</span>
                  </li>
                  {renderFilters()}
                </ul>
                {isUserLoggedIn && (
                  <div className="new">
                    <div
                      className="new-photo"
                      style={{ height: 100 }}
                      onClick={e => toggleModal(e, 'addPhoto')}
                    >
                      <div className="add-new">
                        <span className="title">add photo</span>
                      </div>
                    </div>
                    <div
                      className="new-category"
                      style={{ height: 100 }}
                      onClick={e => toggleModal(e, 'addCategory')}
                    >
                      <div className="add-new">
                        <span className="title">add category</span>
                      </div>
                    </div>
                  </div>
                )}
                {photos.length > 0 && (
                  <div className="photos-items">
                    <ul>{renderPhotos()}</ul>
                    {page < totalPages && !innerLoading && (
                      <div className="load-more-block">
                        <span
                          onClick={() => {
                            setPage(page + 1)
                            onGetMorePhotos({ galleryFilter, page: page + 1 })
                          }}
                        >
                          Load more
                        </span>
                      </div>
                    )}
                    {innerLoading && (
                      <div className="load-more-block">
                        <span>Loading...</span>
                      </div>
                    )}
                  </div>
                )}
                {photos.length === 0 && (
                  <div className="empty-block">
                    <p className="no-photos">No photos</p>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {isModalShown && modalType === 'addPhoto' && (
        <AddPhotoModal
          isModalDisabled={isModalDisabled}
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          onAddPhoto={onAddPhoto}
          photosCategories={photosCategories}
        />
      )}
      {isModalShown && modalType === 'editPhoto' && (
        <EditPhotoModal
          isModalDisabled={isModalDisabled}
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          onEditPhoto={onEditPhoto}
          photosCategories={photosCategories}
          modalData={modalData}
        />
      )}
      {isModalShown && modalType === 'deletePhoto' && (
        <ConfirmModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          modalData={modalData}
          isModalDisabled={isModalDisabled}
          onDelete={onDeletePhoto}
        />
      )}
      {isModalShown && modalType === 'addCategory' && (
        <AddCategoryModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          isModalDisabled={isModalDisabled}
          onAdd={onAddCategory}
        />
      )}
      {isModalShown && modalType === 'editCategory' && (
        <EditCategoryModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          modalData={modalData}
          isModalDisabled={isModalDisabled}
          onEdit={onEditCategory}
        />
      )}
      {isModalShown && modalType === 'deleteCategory' && (
        <ConfirmModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          modalData={modalData}
          isModalDisabled={isModalDisabled}
          onDelete={onDeleteCategory}
          setGalleryFilter={onSetGalleryFilter}
        />
      )}
    </div>
  )
}

Gallery.propTypes = {}

Gallery.defaultProps = {}

const mapStateToProps = state => ({
  isUserLoggedIn: state.user.isUserLoggedIn,
  arePhotosLoading: state.gallery.arePhotosLoading,
  isModalDisabled: state.gallery.isModalDisabled,
  photosCategories: state.gallery.photosCategories,
  shouldReload: state.gallery.shouldReload,
  photos: state.gallery.photos,
  totalPages: state.gallery.totalPages,
  innerLoading: state.gallery.innerLoading,
  galleryFilter: state.gallery.galleryFilter,
})

const mapDispatchToProps = dispatch => ({
  onGetPhotos: action => dispatch(getPhotos(action)),
  onGetMorePhotos: action => dispatch(getMorePhotos(action)),
  onResetState: () => dispatch(resetState()),
  onAddPhoto: action => dispatch(addPhoto(action)),
  onEditPhoto: action => dispatch(editPhoto(action)),
  onDeletePhoto: action => dispatch(deletePhoto(action)),
  onAddCategory: action => dispatch(addCategory(action)),
  onEditCategory: action => dispatch(editCategory(action)),
  onDeleteCategory: action => dispatch(deleteCategory(action)),
  onSetGalleryFilter: action => dispatch(setGalleryFilter(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
