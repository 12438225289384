import { combineReducers } from 'redux'

import user from '../redux/user/reducer'
import carousel from '../redux/carousel/reducer'
import videos from '../redux/videos/reducer'
import team from '../redux/team/reducer'
import gallery from '../redux/gallery/gallery'

const appReducer = combineReducers({
  user,
  carousel,
  videos,
  team,
  gallery,
})

const rootReducer = (state, action) => {
  let reduxState = state
  return appReducer(reduxState, action)
}

export default rootReducer
