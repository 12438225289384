import { put, all, takeLatest } from 'redux-saga/effects'
import { TeamService } from '../../services/api'

import {
  getTeamMembersSuccess,
  addTeamMemberSuccess,
  addTeamMemberFailure,
  editTeamMemberSuccess,
  editTeamMemberFailure,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure,
  onGetPartnersFailure,
  onAddPartnerFailure,
  onDeletePartnerFailure,
  onAddPartnerSuccess,
  onGetPartnersSuccess,
  onDeletePartnerSuccess,
} from './actions'
import {
  GET_TEAM_MEMBERS,
  ADD_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  GET_PARTNERS,
  ADD_PARTNER,
  DELETE_PARTNER,
} from '../constants'
import { notification } from 'antd'

function* fetchTeamMembers() {
  try {
    const teamMembers = yield TeamService.getTeamMembers()
    yield put(getTeamMembersSuccess(teamMembers))
  } catch (error) {
    console.error('Error')
  }
}

function* addTeamMember(action) {
  try {
    const res = yield TeamService.addTeamMember(action.payload)
    if (res.message === 'success') {
      notification.success({
        message: 'Added successfully',
      })
      yield put(addTeamMemberSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(addTeamMemberFailure())
      return notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
    }
  } catch (err) {
    yield put(addTeamMemberFailure())
    return notification.error({
      message: 'Unexpected error',
      description: 'Something went wrong',
    })
  }
}
function* editTeamMember(action) {
  try {
    const res = yield TeamService.editTeamMember(action.payload)
    if (res.id || res.status) {
      notification.success({
        message: 'Edited successfully',
      })
      yield put(editTeamMemberSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(editTeamMemberFailure())
      return notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
    }
  } catch (err) {
    yield put(editTeamMemberFailure())
    return notification.error({
      message: 'Unexpected error',
      description: 'Something went wrong',
    })
  }
}
function* deleteTeamMember(action) {
  try {
    const res = yield TeamService.deleteTeamMember(action.payload)
    if (res.message === 'success') {
      notification.success({
        message: 'Deleted successfully',
      })
      yield put(deleteTeamMemberSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(deleteTeamMemberFailure())
      return notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
    }
  } catch (err) {
    yield put(deleteTeamMemberFailure())
    return notification.error({
      message: 'Unexpected error',
      description: 'Something went wrong',
    })
  }
}

function* getPartners() {
  try {
    const res = yield TeamService.getPartners()

    if (res) {
      yield put(onGetPartnersSuccess(res))
    }
  } catch (err) {
    yield put(onGetPartnersFailure())
  }
}

function* addPartner(action) {
  try {
    const res = yield TeamService.addPartner(action.payload)

    if (res.message === 'success') {
      notification.success({
        message: 'Added successfully',
      })
      yield put(onAddPartnerSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(onAddPartnerFailure())
      return notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
    }
  } catch (err) {
    yield put(onAddPartnerFailure())
  }
}

function* deletePartner(action) {
  try {
    const res = yield TeamService.deletePartner(action.payload)

    if (res.message === 'success') {
      notification.success({
        message: 'Deleted successfully',
      })
      yield put(onDeletePartnerSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(onDeletePartnerFailure())
      return notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
    }
  } catch (err) {
    yield put(onDeletePartnerFailure())
  }
}

function* librarySagas() {
  yield all([
    takeLatest(GET_TEAM_MEMBERS, fetchTeamMembers),
    takeLatest(ADD_TEAM_MEMBER, addTeamMember),
    takeLatest(EDIT_TEAM_MEMBER, editTeamMember),
    takeLatest(DELETE_TEAM_MEMBER, deleteTeamMember),
    takeLatest(GET_PARTNERS, getPartners),
    takeLatest(ADD_PARTNER, addPartner),
    takeLatest(DELETE_PARTNER, deletePartner),
  ])
}

export default librarySagas
