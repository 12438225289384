import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { connect } from 'react-redux'

import MainLayout from './main/MainLayout'
import MainLoginLayout from './main/MainLoginLayout'
import { FollowUs } from '../components'

import { lightTheme, darkTheme } from '../style/theme'
import GlobalStyles from '../style/global_theme'
import useDarkMode from '../style/useDarkMode'
import { routes, userRoutes } from '../flows/router'
import { refreshSession } from '../redux/user/actions'

const Container = ({ onRefreshSession }) => {
  const [theme, toggleTheme] = useDarkMode()
  const location = useLocation()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (token) {
      onRefreshSession(token)
    }
  }, [onRefreshSession, token])

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [location.pathname])

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <Switch location={location}>
          {userRoutes.map(route => (
            <MainLoginLayout
              toggleTheme={toggleTheme}
              key={route.path}
              {...route}
            />
          ))}
          {routes.map(route => (
            <MainLayout
              theme={theme}
              toggleTheme={toggleTheme}
              key={route.path}
              {...route}
            />
          ))}
        </Switch>
        <FollowUs />
      </>
    </ThemeProvider>
  )
}

Container.propTypes = {
  onRefreshSession: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  onRefreshSession: action => dispatch(refreshSession(action)),
})

export default connect(null, mapDispatchToProps)(Container)
