import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Icon,
  Slider,
  notification,
} from 'antd'

import { addSlide } from '../../../redux/carousel/actions'

import 'antd/dist/antd.css'

const AddSlideModal = ({
  isModalShown,
  toggleModal,
  videos,
  isModalDisabled,
  form: { getFieldDecorator, validateFields },
  onAddSlide,
}) => {
  const [file, setFile] = useState(null)
  const [hasFile, setHasFile] = useState(false)
  const [transparency, setTransparency] = useState(0)
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState(null)

  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        if (file) {
          onAddSlide({ values, toggleModal })
        } else {
          notification.error({
            message: 'Missed image',
            description: 'You should upload some image',
          })
        }
      }
    })
  }

  const handleChange = file => {
    if (file && file.fileList.length > 0) {
      const reader = new FileReader()

      reader.onloadend = function () {
        return setImageThumbnailUrl(String(reader.result))
      }

      reader.readAsDataURL(file.file)
    }
    setTransparency(0)
    setImageThumbnailUrl(null)
    if (file && file.fileList.length > 0) {
      setFile(file.file)
      setHasFile(true)
      const reader = new FileReader()

      reader.onloadend = () => {
        console.log(reader.result)
      }
      return null
    }
    setFile(null)
    setHasFile(false)
  }

  const parseVideos = () =>
    videos.map(category => {
      const { caption, _id } = category

      return (
        <Select.Option key={_id} value={_id}>
          {caption}
        </Select.Option>
      )
    })

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Add slide</h2>
        <Form.Item label="Project">
          {getFieldDecorator('caption')(
            <Input size="default" type="text" placeholder="Project" />
          )}
        </Form.Item>
        <Form.Item label="Client">
          {getFieldDecorator('text')(
            <Input size="default" type="text" placeholder="Client" />
          )}
        </Form.Item>
        <Form.Item label="Image">
          {getFieldDecorator('image', {
            rules: [{ required: true, message: 'This field is required' }],
          })(
            <Upload
              beforeUpload={() => false}
              multiple={false}
              onChange={handleChange}
              accept=".png, .jpg"
              isImageUrl
            >
              <Button disabled={hasFile}>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>
          )}
        </Form.Item>
        {imageThumbnailUrl && (
          <>
            <div className="thumbnail">
              <div
                style={{
                  background: `linear-gradient(135deg, rgba(0, 0, 0, ${transparency}%) 35%, rgba(255, 255, 255, 0) 100%)`,
                }}
                className="thumbnail-preview"
              ></div>
              <img
                className="thumnnail"
                src={imageThumbnailUrl}
                alt="Slide thumbnail"
              />
            </div>
            <Form.Item
              style={{ margin: '20px 0 0 0' }}
              label="Background transparency"
            >
              {getFieldDecorator('transparency', {
                initialValue: 0,
              })(<Slider onChange={value => setTransparency(Number(value))} />)}
            </Form.Item>
          </>
        )}
        <Form.Item label="Video to show">
          {getFieldDecorator('videoId')(
            <Select placeholder="Video to show" size="default">
              {videos && parseVideos()}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  onAddSlide: action => dispatch(addSlide(action)),
})

AddSlideModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  videos: PropTypes.array,
  getFieldDecorator: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  onAddSlide: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

AddSlideModal.defaultProps = {
  videos: [],
}

export default connect(null, mapDispatchToProps)(Form.create()(AddSlideModal))
