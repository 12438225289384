import {
  CarouselSlider,
  Videos,
  Video,
  Team,
  Contacts,
  Gallery,
  NotFound,
  VideosCarousel,
  Login,
  ShootingIn,
} from '../components'

const routes = [
  {
    exact: true,
    path: '/Ukraine',
    component: ShootingIn,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/Georgia',
    component: ShootingIn,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/Kazakhstan',
    component: ShootingIn,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/videos/watch/:videoId',
    component: Video,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/videos/watch',
    component: VideosCarousel,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/videos',
    component: Videos,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/team',
    component: Team,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/contacts',
    component: Contacts,
    shouldFooterBeVisible: false,
  },
  {
    exact: true,
    path: '/gallery',
    component: Gallery,
    shouldFooterBeVisible: true,
  },
  {
    exact: true,
    path: '/',
    component: CarouselSlider,
    shouldFooterBeVisible: false,
  },
  {
    path: '*',
    component: NotFound,
  },
]

const userRoutes = [
  {
    exact: true,
    path: '/admin',
    component: Login,
  },
]

export { routes, userRoutes }
