import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Vimeo from '@u-wave/react-vimeo'
import { useLocation, useHistory } from 'react-router'
import { Icon } from 'antd'

import Loading from '../../Loading'

import { getVideo } from '../../../redux/videos/actions'

import './Videos.scss'
import './VideosCarousel.scss'

const Video = ({ onGetVideo, isVideoLoading, singleVideo }) => {
  const videoId = window.location.href.split('/').reverse()[0]
  const location = useLocation()
  const history = useHistory()
  const { state } = location

  useEffect(() => {
    onGetVideo(videoId)
  }, [onGetVideo, videoId])

  return (
    <div className={`video-wrapper ${singleVideo ? '' : 'empty'}`}>
      {state?.backroute ? (
        <span
          className="goBack"
          onClick={() => {
            history.push(state.backroute)
          }}
        >
          <Icon type="close" style={{ fontSize: 35 }} />
        </span>
      ) : null}
      <div className="video">
        {isVideoLoading ? (
          <Loading />
        ) : (
          <>
            {singleVideo ? (
              <div key={singleVideo._id} className="video">
                <Vimeo
                  autopause
                  video={singleVideo.url}
                  height={600}
                  width={920}
                  className="vimeo"
                />
                <div className="video-description">
                  <div className="main-info">
                    <h1>{singleVideo.caption}</h1>
                    <p>{singleVideo.production}</p>
                  </div>
                  {singleVideo.director ||
                  singleVideo.pop ||
                  singleVideo.production ? (
                    <div className="video-additional-description">
                      {singleVideo.director && (
                        <span className="main">
                          Director:{' '}
                          <span>{singleVideo.director || 'No data'}</span>
                        </span>
                      )}
                      {singleVideo.pop && (
                        <span className="main">
                          DOP: <span>{singleVideo.pop}</span>
                        </span>
                      )}
                      {singleVideo.production && (
                        <span className="main">
                          Production company:{' '}
                          <span>{singleVideo.production}</span>
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <span className="slide-caption">video does not exist</span>
            )}
          </>
        )}
      </div>
    </div>
  )
}

Video.propTypes = {}

Video.defaultProps = {}

const mapStateToProps = state => ({
  isVideoLoading: state.videos.isVideoLoading,
  singleVideo: state.videos.singleVideo,
})

const mapDispatchToProps = dispatch => ({
  onGetVideo: action => dispatch(getVideo(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Video)
