import {
  SIGN_UP,
  SIGN_IN,
  SIGN_OUT,
  SET_THEME,
  REFRESH_SESSION,
  SUCCESS,
  FAILURE,
} from '../constants'

export const signUp = action => ({
  type: SIGN_UP,
  payload: action,
})

export const signIn = action => ({
  type: SIGN_IN,
  payload: action,
})

export const signInSuccess = user => ({
  type: SIGN_IN + SUCCESS,
  user,
})

export const signInFailure = () => ({
  type: SIGN_IN + FAILURE,
})

export const signOut = () => ({
  type: SIGN_OUT,
})

export const setTheme = action => ({
  type: SET_THEME,
  payload: action,
})

export const refreshSession = action => ({
  type: REFRESH_SESSION,
  payload: action,
})
