class HttpService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async request(path, method, body, isFormData = false) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-Access-Token': localStorage.getItem('token'),
    })

    const defaultHeader = new Headers({
      'X-Access-Token': localStorage.getItem('token'),
    })

    const request = isFormData
      ? new Request(`${this.baseUrl}${path}`, {
          method,
          headers: defaultHeader,
          body,
        })
      : new Request(`${this.baseUrl}${path}`, {
          method,
          headers,
          body,
        })

    try {
      const response = await fetch(request)
      return response.json()
    } catch (error) {
      console.log('error', error.message)
    }
  }

  get(url) {
    return this.request(url, 'GET')
  }

  post(url, body, isFormData = false) {
    return this.request(url, 'POST', body, isFormData)
  }

  put(url, newHeader) {
    return this.request(url, 'PUT', newHeader)
  }

  delete(url, newHeader) {
    return this.request(url, 'DELETE', newHeader)
  }
}

export default HttpService
