import React from 'react'

import darkGif from '../style/source/icons/loader-animation-on-black-without-text.gif'
import lightGif from '../style/source/icons/loader-animation-on-white-without-text.gif'

const Loading = () => {
  const theme = localStorage.getItem('theme') === 'dark' ? darkGif : lightGif

  return (
    <div className="text-centered">
      <img className="loading-icon" src={theme} alt="Loading" />
    </div>
  )
}

export default Loading
