import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Form,
  Input,
  Upload,
  Icon,
  Select,
  notification,
} from 'antd'

import 'antd/dist/antd.css'

const AddPhotoModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  onAddPhoto,
  isModalDisabled,
  photosCategories,
}) => {
  const [file, setFile] = useState(null)
  const [hasFile, setHasFile] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        if (hasFile) {
          onAddPhoto({ values, toggleModal })
        } else {
          notification.error({
            message: 'File is not attached',
          })
        }
      }
    })
  }

  const handleChange = file => {
    if (file && file.fileList.length >= 1) {
      setFile(file.file)
      setHasFile(true)
      return null
    }
    setFile(null)
    setHasFile(false)
  }

  const parseCategories = () =>
    photosCategories.map(category => {
      const { caption, _id } = category
      return (
        <Select.Option key={_id} value={_id}>
          {caption}
        </Select.Option>
      )
    })

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Add photo</h2>
        <Form.Item label="Caption">
          {getFieldDecorator('caption')(
            <Input size="default" type="text" placeholder="Caption" />
          )}
        </Form.Item>
        <Form.Item label="Text">
          {getFieldDecorator('text')(
            <Input size="default" type="text" placeholder="Text" />
          )}
        </Form.Item>
        <Form.Item label="Photo">
          {getFieldDecorator('photo', {
            rules: [{ required: true, message: 'This field is required' }],
          })(
            <Upload
              beforeUpload={() => false}
              multiple={false}
              onChange={handleChange}
              accept=".png, .jpg"
            >
              <Button disabled={hasFile}>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item label="Photo category">
          {getFieldDecorator('photoCategory')(
            <Select placeholder="Photo category" size="default">
              {photosCategories && parseCategories()}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

AddPhotoModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onAddPhoto: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

export default Form.create()(AddPhotoModal)
