import {
  GET_TEAM_MEMBERS,
  SUCCESS,
  FAILURE,
  ADD_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  GET_PARTNERS,
  ADD_PARTNER,
  DELETE_PARTNER,
} from '../constants'

export const getTeamMembers = () => ({
  type: GET_TEAM_MEMBERS,
})

export const getTeamMembersSuccess = teamMembers => ({
  type: GET_TEAM_MEMBERS + SUCCESS,
  teamMembers,
})

export const getTeamMembersFailure = () => ({
  type: GET_TEAM_MEMBERS + FAILURE,
})

export const addTeamMember = action => ({
  type: ADD_TEAM_MEMBER,
  payload: action,
})

export const addTeamMemberSuccess = () => ({
  type: ADD_TEAM_MEMBER + SUCCESS,
})

export const addTeamMemberFailure = () => ({
  type: ADD_TEAM_MEMBER + FAILURE,
})

export const editTeamMember = action => ({
  type: EDIT_TEAM_MEMBER,
  payload: action,
})

export const editTeamMemberSuccess = () => ({
  type: EDIT_TEAM_MEMBER + SUCCESS,
})

export const editTeamMemberFailure = () => ({
  type: EDIT_TEAM_MEMBER + FAILURE,
})

export const deleteTeamMember = action => ({
  type: DELETE_TEAM_MEMBER,
  payload: action,
})

export const deleteTeamMemberSuccess = () => ({
  type: DELETE_TEAM_MEMBER + SUCCESS,
})

export const deleteTeamMemberFailure = () => ({
  type: DELETE_TEAM_MEMBER + FAILURE,
})

export const resetState = () => ({
  type: 'RESET_MEMBERS_STATE',
})

export const onGetPartners = () => ({
  type: GET_PARTNERS,
})

export const onGetPartnersSuccess = action => ({
  type: GET_PARTNERS + SUCCESS,
  payload: action,
})

export const onGetPartnersFailure = () => ({
  type: GET_PARTNERS + FAILURE,
})

export const onAddPartner = action => ({
  type: ADD_PARTNER,
  payload: action,
})

export const onAddPartnerSuccess = action => ({
  type: ADD_PARTNER + SUCCESS,
  payload: action,
})

export const onAddPartnerFailure = action => ({
  type: ADD_PARTNER + FAILURE,
  payload: action,
})

export const onDeletePartner = action => ({
  type: DELETE_PARTNER,
  payload: action,
})

export const onDeletePartnerSuccess = action => ({
  type: DELETE_PARTNER + SUCCESS,
  payload: action,
})

export const onDeletePartnerFailure = action => ({
  type: DELETE_PARTNER + FAILURE,
  payload: action,
})
