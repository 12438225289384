import { put, all, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { CarouselSliderService } from '../../services/api'

import {
  getSliderDataSuccess,
  getSliderDataFailure,
  addSlideSuccess,
  addSlideFailure,
  editSlideSuccess,
  editSlideFailure,
  deleteSlideSuccess,
  deleteSlideFailure,
} from './actions'
import {
  GET_SLIDER_DATA,
  ADD_SLIDE,
  EDIT_SLIDE,
  DELETE_SLIDE,
} from '../constants'

export function* fetchSliderData() {
  try {
    const response = yield CarouselSliderService.getSliderData()

    if (response) {
      yield put(getSliderDataSuccess(response))
    } else {
      yield put(getSliderDataFailure())
    }
  } catch (error) {
    yield put(getSliderDataFailure())
    console.error('Error')
  }
}

export function* addSlide(action) {
  try {
    const res = yield CarouselSliderService.addSlide(action.payload)

    if (res.message === 'success') {
      notification.success({
        message: 'Added successfully',
      })
      yield put(addSlideSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(addSlideFailure())
    }
  } catch (error) {
    notification.error({
      message: 'Unexpected error',
      description: 'Something went wrong',
    })
    yield put(addSlideFailure())
  }
}

export function* editSlide(action) {
  try {
    const res = yield CarouselSliderService.editSlide(action.payload)

    if (res.id || res.status === 200) {
      notification.success({
        message: 'Edit successfully',
      })
      yield put(editSlideSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(editSlideFailure())
    }
  } catch (error) {
    notification.error({
      message: 'Unexpected error',
      description: 'Something went wrong',
    })
    yield put(editSlideFailure())
  }
}

export function* deleteSlide(action) {
  try {
    const res = yield CarouselSliderService.deleteSlide(action.payload)

    if (res.status === 200) {
      notification.success({
        message: 'Deleted successfully',
      })
      yield put(deleteSlideSuccess())
      return action.payload.toggleModal()
    } else {
      yield put(deleteSlideFailure())
    }
  } catch (error) {
    notification.error({
      message: 'Unexpected error',
      description: 'Something went wrong',
    })
    yield put(deleteSlideFailure())
  }
}

function* librarySagas() {
  yield all([
    takeLatest(GET_SLIDER_DATA, fetchSliderData),
    takeLatest(ADD_SLIDE, addSlide),
    takeLatest(EDIT_SLIDE, editSlide),
    takeLatest(DELETE_SLIDE, deleteSlide),
  ])
}

export default librarySagas
