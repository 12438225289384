export const SUCCESS = '_SUCCESS'
export const FAILURE = '_FAILURE'

export const SIGN_UP = 'SIGN_UP'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const SET_THEME = 'SET_THEME'

export const GET_VIDEOS = 'GET_VIDEOS'
export const GET_MORE_VIDEOS = 'GET_MORE_VIDEOS'
export const GET_ALL_VIDEOS = 'GET_ALL_VIDEOS'
export const GET_VIDEO = 'GET_VIDEO'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const SET_CHOOSED_VIDEO = 'SET_CHOOSED_VIDEO'
export const ADD_VIDEO = 'ADD_VIDEO'
export const EDIT_VIDEO = 'EDIT_VIDEO'
export const DELETE_VIDEO = 'DELETE_VIDEO'
export const UPDATE_COPY_VIDEOS = 'UPDATE_COPY_VIDEOS'

export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS'
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER'
export const EDIT_TEAM_MEMBER = 'EDIT_TEAM_MEMBER'
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER'

export const GET_SLIDER_DATA = 'GET_SLIDER_DATA'
export const ADD_SLIDE = 'ADD_SLIDE'
export const EDIT_SLIDE = 'EDIT_SLIDE'
export const DELETE_SLIDE = 'DELETE_SLIDE'

export const ADD_CATEGORY = 'ADD_CATEGORY'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export const REFRESH_SESSION = 'REFRESH_SESSION'

export const ACTION_SUCCESS = '2332/general/ACTION_SUCCESS'
export const ACTION_FAILURE = '2332/general/ACTION_FAILURE'

export const UPDATE_VIDEOS_LIST = 'UPDATE_VIDEOS_LIST'

export const GET_PARTNERS = 'GET_PARTNERS'
export const ADD_PARTNER = 'ADD_PARTNER'
export const DELETE_PARTNER = 'DELETE_PARTNER'
