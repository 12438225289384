const white = '#fff'
const black = '#000'

export const darkTheme = {
  backgroundColor: '#060606',
  textColor: white,
  linkBackgroundColor: white,
  logoBackgroundColor: black,
  followUsBackground: white,
  followUsText: black,
  lampColor: white,
  listColor: white,

  reverseLogoColor: white,
  footerBorderColor: white,
  footerTextColor: white,
  footerIconsReverse: white,
}

export const lightTheme = {
  backgroundColor: white,
  textColor: black,
  linkBackgroundColor: black,
  logoBackgroundColor: white,
  followUsBackground: black,
  followUsText: white,
  lampColor: black,
  listColor: black,

  reverseLogoColor: black,
  footerBorderColor: black,
  footerTextColor: black,
  footerIconsReverse: black,
}
