import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class VideosService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }

  getVideos({ videoFilter, page }) {
    return this.request.get(
      `/api/gallery/all/gallery?${
        videoFilter ? `category=${videoFilter}&` : ''
      }page=${page}`
    )
  }

  getVideo(videoId) {
    return this.request.get(`/api/gallery/${videoId}`)
  }

  getAllVideos() {
    return this.request.get('/api/gallery/gallery-all')
  }

  getCategories() {
    return this.request.get(`/api/gallery_category/all/gallery`)
  }

  getVimeoData(videos) {
    const promises = videos.map(video =>
      fetch(
        `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
          video.url
        )}`,
        {
          method: 'GET',
        }
      )
    )

    return Promise.all(promises)
      .then(responses => Promise.all(responses.map(res => res.json())))
      .then(videos => videos)
  }

  addVideo({
    values: {
      videoUrl,
      videoCaption,
      videoCategory,
      previewImage,
      director,
      dop,
      production,
    },
  }) {
    const formData = new FormData()

    formData.append('url', videoUrl)
    formData.append('caption', videoCaption)
    formData.append('category', videoCategory)
    formData.append('type', 'gallery')
    if (previewImage) {
      formData.append('image', previewImage.file)
    }
    if (director) {
      formData.append('director', director)
    }
    if (dop) {
      formData.append('pop', dop)
    }
    if (production) {
      formData.append('production', production)
    }
    return this.request.post('/api/gallery/add', formData, true)
  }

  editVideo({
    values: {
      videoUrl,
      videoCaption,
      videoCategory,
      previewImage,
      director,
      dop,
      production,
    },
    _id,
    isImageDeleted,
  }) {
    const formData = new FormData()

    formData.append('url', videoUrl)
    formData.append('caption', videoCaption)
    formData.append('category', videoCategory)
    formData.append('type', 'gallery')
    if (isImageDeleted) {
      formData.append('image', null)
    } else if (previewImage) {
      formData.append('image', previewImage.file)
    }
    if (director) {
      formData.append('director', director)
    }
    if (dop) {
      formData.append('pop', dop)
    }
    if (production) {
      formData.append('production', production)
    }
    return this.request.post(`/api/gallery/update/${_id}`, formData, true)
  }

  deleteVideo({ modalData: { _id } }) {
    return this.request.get(`/api/gallery/remove/${_id}`)
  }

  addCategory({ values: { caption } }) {
    return this.request.post(
      `/api/gallery_category/add`,
      JSON.stringify({ caption, type: 'gallery' })
    )
  }

  editCategory({ values: { caption }, _id }) {
    return this.request.post(
      `/api/gallery_category/update/${_id}`,
      JSON.stringify({ caption, type: 'gallery' })
    )
  }

  deleteCategory({ modalData: { _id } }) {
    return this.request.get(`/api/gallery_category/remove/${_id}`)
  }

  updateVideosList({ allVideos }) {
    const formData = new FormData()

    formData.append('list', JSON.stringify(allVideos))
    return this.request.post(`/api/gallery/gallery-all-update`, formData, true)
  }
}

export default new VideosService()
