import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input } from 'antd'

import { editCategory } from '../../../redux/videos/actions'

import 'antd/dist/antd.css'

const EditCategoryModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  onEditCategory,
  isModalDisabled,
  modalData: { caption, _id },
  onEdit = null,
}) => {
  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        if (onEdit) {
          onEdit({ values, toggleModal, _id })
        } else {
          onEditCategory({ values, toggleModal, _id })
        }
      }
    })
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Edit category</h2>
        <Form.Item label="Caption">
          {getFieldDecorator('caption', {
            rules: [{ required: true, message: 'This field is required' }],
            initialValue: caption,
          })(<Input size="default" type="text" placeholder="Caption" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  onEditCategory: action => dispatch(editCategory(action)),
})

EditCategoryModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onEditCategory: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
  modalData: PropTypes.object.isRequired,
}

export default connect(
  null,
  mapDispatchToProps
)(Form.create()(EditCategoryModal))
