import {
  SUCCESS,
  FAILURE,
  SIGN_UP,
  SIGN_IN,
  SIGN_OUT,
  REFRESH_SESSION,
  SET_THEME,
} from '../constants'

const initialState = {
  isLogginingIn: false,
  isUserLoggedIn: false,
  token: null,
  userId: null,
  username: null,
  theme: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        areVideosLoading: true,
      }
    case SIGN_IN:
      return {
        ...state,
        isLogginingIn: true,
      }
    case SIGN_IN + SUCCESS:
      return {
        ...state,
        isLogginingIn: false,
        isUserLoggedIn: true,
        token: action.user.token,
        userId: action.user.userId,
        username: action.user.username,
      }
    case SIGN_IN + FAILURE:
      return {
        ...state,
        isLogginingIn: false,
      }
    case SIGN_OUT:
      localStorage.removeItem('token')
      return {
        ...initialState,
        isUserLoggedIn: false,
      }
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      }
    case REFRESH_SESSION:
      return {
        ...state,
        token: action.payload.token,
        isUserLoggedIn: true,
      }
    default:
      return state
  }
}

export default reducer
