import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Icon,
  Slider,
  notification,
} from 'antd'

import { editSlide } from '../../../redux/carousel/actions'

import './EditSlideModal.scss'
import 'antd/dist/antd.css'

const EditSlideModal = ({
  isModalShown,
  toggleModal,
  videos,
  isModalDisabled,
  form: { getFieldDecorator, validateFields },
  onEditSlide,
  modalData: {
    caption,
    date,
    image: originThumbnail,
    imageId,
    text,
    videoId,
    _id,
    transparency: originTransparency,
  },
}) => {
  const [file, setFile] = useState(null)
  const [hasFile, setHasFile] = useState(false)
  const [transparency, setTransparency] = useState(0)
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState(null)
  const [isChangingImageProcess, setChangingImageProcess] = useState(false)
  const [
    isEditingExistingImageProcess,
    setEditingExistingImageProcess,
  ] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        if (file || (!isChangingImageProcess && !file)) {
          onEditSlide({
            values,
            toggleModal,
            imageId,
            _id,
            originTransparency,
            isEditingExistingImageProcess,
          })
        } else {
          notification.error({
            message: 'Missed image',
            description: 'You should upload some image',
          })
        }
      }
    })
  }

  const handleChange = file => {
    if (file && file.fileList.length > 0) {
      var reader = new FileReader()
      reader.onloadend = function () {
        return setImageThumbnailUrl(String(reader.result))
      }

      reader.readAsDataURL(file.file)
    }
    setTransparency(0)
    setImageThumbnailUrl(null)
    if (file && file.fileList.length > 0) {
      setFile(file.file)
      setHasFile(true)
      const reader = new FileReader()
      reader.onloadend = () => {
        console.log(reader.result)
      }
      return null
    }
    setFile(null)
    setHasFile(false)
  }

  const parseVideos = () =>
    videos.map(category => {
      const { caption, _id } = category
      return (
        <Select.Option key={_id} value={_id}>
          {caption}
        </Select.Option>
      )
    })

  const clearProcess = () => {
    setEditingExistingImageProcess(false)
    setChangingImageProcess(false)
    setTransparency(0)
    setFile(null)
    setHasFile(false)
    setImageThumbnailUrl(null)
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
      className="editSlide"
    >
      <Form layout="vertical">
        <h2>Edit slide</h2>
        <Form.Item label="Project">
          {getFieldDecorator('caption', {
            initialValue: caption,
          })(<Input size="default" type="text" placeholder="Project" />)}
        </Form.Item>
        <Form.Item label="Client">
          {getFieldDecorator('text', {
            initialValue: text,
          })(<Input size="default" type="text" placeholder="Client" />)}
        </Form.Item>
        {!isChangingImageProcess && originThumbnail && (
          <>
            <div className="ant-form-item-label">
              <label for="text" title="Text">
                Image
              </label>
            </div>
            <div className="thumbnail">
              <div
                style={{
                  background: `linear-gradient(155deg, rgba(0, 0, 0, ${
                    isEditingExistingImageProcess
                      ? transparency
                      : originTransparency
                  }%) 30%, rgba(255, 255, 255, 0) 100%)`,
                }}
                className="thumbnail-preview"
              ></div>
              <img src={originThumbnail} alt="Slide thumbnail" />
            </div>
            {isEditingExistingImageProcess && (
              <Form.Item
                style={{ margin: '20px 0 0 0' }}
                label="Background transparency"
              >
                {getFieldDecorator('transparency', {
                  initialValue: 0,
                })(
                  <Slider onChange={value => setTransparency(Number(value))} />
                )}
              </Form.Item>
            )}
          </>
        )}
        {isChangingImageProcess && (
          <div>
            <Form.Item style={{ margin: 0 }} label="Image">
              {getFieldDecorator('image', {
                rules: [{ required: true, message: 'This field is required' }],
              })(
                <Upload
                  beforeUpload={() => false}
                  multiple={false}
                  onChange={handleChange}
                  accept=".png, .jpg"
                  isImageUrl
                >
                  <Button disabled={hasFile}>
                    <Icon type="upload" /> Click to upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
            {imageThumbnailUrl && (
              <>
                <div className="thumbnail">
                  <div
                    style={{
                      background: `linear-gradient(135deg, rgba(0, 0, 0, ${transparency}%) 35%, rgba(255, 255, 255, 0) 100%)`,
                    }}
                    className="thumbnail-preview"
                  ></div>
                  <img
                    className="thumnnail"
                    src={imageThumbnailUrl}
                    alt="Slide thumbnail"
                  />
                </div>
                <Form.Item
                  style={{ margin: '20px 0 0 0' }}
                  label="Background transparency"
                >
                  {getFieldDecorator('transparency', {
                    initialValue: 0,
                  })(
                    <Slider
                      onChange={value => setTransparency(Number(value))}
                    />
                  )}
                </Form.Item>
              </>
            )}
          </div>
        )}
        {!isChangingImageProcess && (
          <button
            className="action-button-edit-modal"
            onClick={() => {
              setChangingImageProcess(true)
              setEditingExistingImageProcess(false)
              return
            }}
          >
            Change image
          </button>
        )}
        {!isEditingExistingImageProcess && (
          <button
            className="action-button-edit-modal"
            onClick={() => {
              setEditingExistingImageProcess(true)
              setChangingImageProcess(false)
              return
            }}
          >
            Edit existing image
          </button>
        )}
        {isChangingImageProcess || isEditingExistingImageProcess ? (
          <button
            className="action-button-edit-modal"
            onClick={() => clearProcess()}
          >
            Reset
          </button>
        ) : null}
        <Form.Item label="Video to show">
          {getFieldDecorator('videoId', {
            initialValue: videoId || 'Choose video',
          })(
            <Select placeholder="Video to show" size="default">
              {videos && parseVideos()}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  onEditSlide: action => dispatch(editSlide(action)),
})

EditSlideModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  videos: PropTypes.array,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onEditSlide: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

EditSlideModal.defaultProps = {
  videos: [],
}

export default connect(null, mapDispatchToProps)(Form.create()(EditSlideModal))
