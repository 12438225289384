import {
  GET_VIDEOS,
  GET_MORE_VIDEOS,
  GET_ALL_VIDEOS,
  GET_VIDEO,
  GET_CATEGORIES,
  SET_CHOOSED_VIDEO,
  UPDATE_COPY_VIDEOS,
  ADD_VIDEO,
  EDIT_VIDEO,
  DELETE_VIDEO,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  SUCCESS,
  FAILURE,
  UPDATE_VIDEOS_LIST,
} from '../constants'

export const getVideos = action => ({
  type: GET_VIDEOS,
  payload: action,
})

export const getMoreVideos = action => ({
  type: GET_MORE_VIDEOS,
  payload: action,
})

export const onGetAllVideos = () => ({
  type: GET_ALL_VIDEOS,
})

export const getAllVideosSuccess = action => ({
  type: GET_ALL_VIDEOS + SUCCESS,
  payload: action,
})

export const getAllVideosFailure = () => ({
  type: GET_ALL_VIDEOS + FAILURE,
})

export const getMoreVideosSuccess = action => ({
  type: GET_MORE_VIDEOS + SUCCESS,
  payload: action,
})

export const getMoreVideosFailure = () => ({
  type: GET_MORE_VIDEOS + FAILURE,
})

export const getVideo = action => ({
  type: GET_VIDEO,
  payload: action,
})

export const getVideoSuccess = video => ({
  type: GET_VIDEO + SUCCESS,
  video,
})

export const getVideoFailure = () => ({
  type: GET_VIDEO + FAILURE,
})

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES + SUCCESS,
  categories,
})

export const getVideosSuccess = ({ videos, categories, totalPages }) => ({
  type: GET_VIDEOS + SUCCESS,
  videos,
  categories,
  totalPages,
})

export const getVideosFailure = () => ({
  type: GET_VIDEOS + FAILURE,
})

export const setChoosedVideo = action => ({
  type: SET_CHOOSED_VIDEO,
  payload: action,
})

export const editVideo = action => ({
  type: EDIT_VIDEO,
  payload: action,
})

export const editVideoSuccess = () => ({
  type: EDIT_VIDEO + SUCCESS,
})

export const editVideoFailure = () => ({
  type: EDIT_VIDEO + FAILURE,
})

export const addVideo = action => ({
  type: ADD_VIDEO,
  payload: action,
})

export const addVideoSuccess = () => ({
  type: ADD_VIDEO + SUCCESS,
})

export const addVideoFailure = () => ({
  type: ADD_VIDEO + FAILURE,
})

export const deleteVideo = action => ({
  type: DELETE_VIDEO,
  payload: action,
})

export const deleteVideoSuccess = () => ({
  type: DELETE_VIDEO + SUCCESS,
})

export const deleteVideoFailure = () => ({
  type: DELETE_VIDEO + FAILURE,
})

// addcategory
export const addCategory = action => ({
  type: ADD_CATEGORY,
  payload: action,
})

export const addCategorySuccess = () => ({
  type: ADD_CATEGORY + SUCCESS,
})

export const addCategoryFailure = () => ({
  type: ADD_CATEGORY + FAILURE,
})

// editcategory
export const editCategory = action => ({
  type: EDIT_CATEGORY,
  payload: action,
})

export const editCategorySuccess = () => ({
  type: EDIT_CATEGORY + SUCCESS,
})

export const editCategoryFailure = () => ({
  type: EDIT_CATEGORY + FAILURE,
})

// deletecategory
export const deleteCategory = action => ({
  type: DELETE_CATEGORY,
  payload: action,
})

export const deleteCategorySuccess = () => ({
  type: DELETE_CATEGORY + SUCCESS,
})

export const deleteCategoryFailure = () => ({
  type: DELETE_CATEGORY + FAILURE,
})

// other
export const resetState = () => ({
  type: 'RESET_VIDEOS_STATE',
})

export const updateCopyVideos = action => ({
  type: UPDATE_COPY_VIDEOS,
  payload: action,
})

export const onUpdateVideosList = action => ({
  type: UPDATE_VIDEOS_LIST,
  payload: action,
})

export const updateVideosListSuccess = () => ({
  type: UPDATE_VIDEOS_LIST + SUCCESS,
})
