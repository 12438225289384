import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class CarouselSliderService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }

  getSliderData() {
    return this.request.get('/api/slider')
  }

  addSlide({ values: { caption, image, text, transparency, videoId } }) {
    const formData = new FormData()
    if (caption) {
      formData.append('caption', caption)
    }
    formData.append('image', image.file)
    formData.append('transparency', transparency)
    if (text) {
      formData.append('text', text)
    }
    if (videoId) {
      formData.append('videoId', videoId)
    }
    return this.request.post('/api/slider/add', formData, true)
  }

  editSlide({
    values: { caption, image, text, transparency, videoId },
    imageId,
    _id,
    originTransparency,
    isEditingExistingImageProcess,
  }) {
    const formData = new FormData()
    if (caption) {
      formData.append('caption', caption)
    }
    if (isEditingExistingImageProcess || (image && image.file)) {
      formData.append('transparency', transparency)
    } else if (!isEditingExistingImageProcess && !image) {
      formData.append('transparency', originTransparency)
    }
    if (image && image.file) {
      formData.append('image', image.file)
    }
    if (text) {
      formData.append('text', text)
    }
    if (videoId) {
      formData.append('videoId', videoId)
    }
    return this.request.post(`/api/slider/update/${_id}`, formData, true)
  }

  deleteSlide({ modalData: { _id, imageId } }) {
    return this.request.get(`/api/slider/remove/${_id}`)
  }
}

export default new CarouselSliderService()
