import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const MainLoginLayout = ({ toggleTheme, ...props }) => {
  return <Route {...props} />
}

MainLoginLayout.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
}

export default MainLoginLayout
