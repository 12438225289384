import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel'
import { connect } from 'react-redux'

import Loading from '../../Loading'
import AddSlideModal from './AddSlideModal'
import EditSlideModal from './EditSlideModal'
import ConfirmModal from '../../ConfirmModal'

import {
  getSliderData,
  resetState,
  deleteSlide,
} from '../../../redux/carousel/actions'
import {
  getVideos,
  resetState as resetVideosState,
} from '../../../redux/videos/actions'

import logo from '../../../style/source/icons/2332.svg'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './CarouselSlider.scss'

const CarouselSlider = ({
  onGetCarouselData,
  onResetState,
  carouselSliderData,
  areSlidesLoading,
  isUserLoggedIn,
  onGetVideos,
  isModalDisabled,
  videos,
  shouldReload,
  onDeleteSlide,
  onResetVideosState,
}) => {
  const history = useHistory()

  const [isModalShown, setModalStatus] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalData, setModalData] = useState({})

  const showFullSlide = ({ videoId }) =>
    history.push(`/videos/watch/${videoId}`, { backroute: '/' })

  const toggleModal = (e = null, type = '', data = {}) => {
    if (e) e.stopPropagation()
    setModalStatus(!isModalShown)
    setModalType(type)
    setModalData(data)
  }

  useEffect(() => {
    onGetCarouselData()
    if (isUserLoggedIn) {
      onGetVideos()
    }
    return () => {
      onResetState()
      onResetVideosState()
    }
  }, [onGetVideos, onGetCarouselData, onResetState, isUserLoggedIn])

  useEffect(() => {
    if (shouldReload) {
      onGetCarouselData()
      if (isUserLoggedIn) {
        onGetVideos()
      }
    }
  }, [shouldReload, onGetCarouselData, isUserLoggedIn, onGetVideos])

  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]

      array[i] = array[j]
      array[j] = temp
    }

    return array
  }

  const renderCarousel = () =>
    carouselSliderData && carouselSliderData.length ? (
      shuffleArray(carouselSliderData).map(carouselSlide => {
        const {
          _id,
          caption,
          image,
          text,
          transparency,
          videoId,
        } = carouselSlide
        return (
          <div
            onClick={() => videoId && showFullSlide(carouselSlide)}
            className={`image-carousel ${!videoId && 'not-clickable'}`}
            key={_id}
          >
            <div
              className="image"
              style={{ backgroundImage: `url("${image}")` }}
            >
              <div
                className="carousel-overlay"
                style={{
                  background: `linear-gradient(135deg, rgba(0, 0, 0, ${transparency}%) 35%, rgba(255, 255, 255, 0) 100%)`,
                }}
              />
            </div>
            <div className="slide-inner">
              <img src={logo} alt="2332 logo" />
              {caption && <h1 className="slide-caption">{caption}</h1>}
              {text && <p className="slide-text">{text}</p>}
            </div>
            {isUserLoggedIn && (
              <div className="slide-actions">
                <div
                  className="edit-slide"
                  onClick={e => toggleModal(e, 'editSlide', carouselSlide)}
                >
                  <div className="edit">
                    <span className="title">edit slide</span>
                  </div>
                </div>
                <div
                  className="delete-slide"
                  onClick={e => toggleModal(e, 'deleteSlide', carouselSlide)}
                >
                  <div className="delete">
                    <span className="title">delete slide</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      })
    ) : (
      <div className="image-carousel no-slides">
        <div className="slide-inner">
          <h1 className="slide-caption">No slides</h1>
          <p className="slide-text">No additional information</p>
        </div>
      </div>
    )

  const whetherToShowIndicators = () => {
    if (!carouselSliderData || carouselSliderData.length <= 1) {
      return false
    }
    return true
  }

  return (
    <div className="carousel-slider main-carousel">
      {areSlidesLoading ? (
        <Loading />
      ) : (
        <>
          <Carousel
            showThumbs={false}
            showIndicators={whetherToShowIndicators()}
            autoPlay={!isModalShown}
            stopOnHover
            swipeable
            showStatus={false}
            infiniteLoop
            interval={5000}
          >
            {renderCarousel()}
          </Carousel>
          {isUserLoggedIn && (
            <div
              className="new-slide"
              onClick={e => toggleModal(e, 'addSlide')}
            >
              <div className="add-new">
                <span className="title">add slide</span>
              </div>
            </div>
          )}
        </>
      )}
      {isModalShown && modalType === 'addSlide' && (
        <AddSlideModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          videos={videos}
          isModalDisabled={isModalDisabled}
        />
      )}
      {isModalShown && modalType === 'editSlide' && (
        <EditSlideModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          videos={videos}
          isModalDisabled={isModalDisabled}
          modalData={modalData}
        />
      )}
      {isModalShown && modalType === 'deleteSlide' && (
        <ConfirmModal
          isModalShown={isModalShown}
          toggleModal={toggleModal}
          isModalDisabled={isModalDisabled}
          modalData={modalData}
          onDelete={onDeleteSlide}
        />
      )}
    </div>
  )
}

CarouselSlider.propTypes = {
  onGetCarouselData: PropTypes.func.isRequired,
  onResetState: PropTypes.func.isRequired,
  areSlidesLoading: PropTypes.bool,
  carouselSliderData: PropTypes.array,
  isUserLoggedIn: PropTypes.bool,
  onGetVideos: PropTypes.func.isRequired,
  videos: PropTypes.array,
  isModalDisabled: PropTypes.bool.isRequired,
}

CarouselSlider.defaultProps = {
  carouselSliderData: [],
  areSlidesLoading: false,
  isUserLoggedIn: false,
  videos: [],
}

const mapStateToProps = state => ({
  carouselSliderData: state.carousel.carouselSliderData,
  areSlidesLoading: state.carousel.areSlidesLoading,
  isUserLoggedIn: state.user.isUserLoggedIn,
  videos: state.videos.videos,
  isModalDisabled: state.carousel.isModalDisabled,
  shouldReload: state.carousel.shouldReload,
})

const mapDispatchToProps = dispatch => ({
  onGetCarouselData: () => dispatch(getSliderData()),
  onResetState: () => dispatch(resetState()),
  onGetVideos: () => dispatch(getVideos({ videoFilter: '', page: 'all' })),
  onDeleteSlide: action => dispatch(deleteSlide(action)),
  onResetVideosState: () => dispatch(resetVideosState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CarouselSlider)
