import {
  GET_SLIDER_DATA,
  ADD_SLIDE,
  EDIT_SLIDE,
  DELETE_SLIDE,
  SUCCESS,
  FAILURE,
} from '../constants'

export const getSliderData = () => ({
  type: GET_SLIDER_DATA,
})

export const getSliderDataSuccess = carouselSliderData => ({
  type: GET_SLIDER_DATA + SUCCESS,
  carouselSliderData,
})

export const getSliderDataFailure = () => ({
  type: GET_SLIDER_DATA + FAILURE,
})

export const resetState = () => ({
  type: 'RESET_CAROUSEL_STATE',
})

// ADD SLIDE

export const addSlide = action => ({
  type: ADD_SLIDE,
  payload: action,
})

export const addSlideSuccess = () => ({
  type: ADD_SLIDE + SUCCESS,
})

export const addSlideFailure = () => ({
  type: ADD_SLIDE + FAILURE,
})

// EDIT SLIDE

export const editSlide = action => ({
  type: EDIT_SLIDE,
  payload: action,
})

export const editSlideSuccess = () => ({
  type: EDIT_SLIDE + SUCCESS,
})

export const editSlideFailure = () => ({
  type: EDIT_SLIDE + FAILURE,
})

// DELETE SLIDE

export const deleteSlide = action => ({
  type: DELETE_SLIDE,
  payload: action,
})

export const deleteSlideSuccess = () => ({
  type: DELETE_SLIDE + SUCCESS,
})

export const deleteSlideFailure = () => ({
  type: DELETE_SLIDE + FAILURE,
})
