import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class UserService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }

  signIn({ values: { email, password } }) {
    return this.request.post(
      '/api/auth/login',
      JSON.stringify({ email, password })
    )
  }
}

export default new UserService()
