import { put, all, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { PhotosGalleryService } from '../../services/api'

import { SUCCESS, FAILURE } from '../constants'

const SET_GALLERY_FILTER = '2332/gallery/SET_GALLERY_FILTER'

const GET_PHOTOS = '2332/gallery/GET_PHOTOS'
const GET_MORE_PHOTOS = '2332/gallery/GET_MORE_PHOTOS'
const GET_CATEGORIES = '2332/gallery/GET_CATEGORIES'

const ADD_PHOTO = '2332/gallery/ADD_PHOTO'
const EDIT_PHOTO = '2332/gallery/EDIT_PHOTO'
const DELETE_PHOTO = 'DELETE_PHOTO'

const ADD_CATEGORY = '2332/gallery/ADD_CATEGORY'
const EDIT_CATEGORY = '2332/gallery/EDIT_CATEGORY'
const DELETE_CATEGORY = '2332/gallery/DELETE_CATEGORY'

const RESET_GALLERY_STATE = '2332/gallery/RESET_GALLERY_STATE'

const ACTION_SUCCESS = '2332/gallery/ACTION_SUCCESS'
const ACTION_FAILURE = '2332/gallery/ACTION_FAILURE'

const initialState = {
  photos: null,
  photosCategories: null,
  arePhotosLoading: true,
  shouldReload: false,
  isModalDisabled: false,
  totalPages: null,
  innerLoading: false,
  galleryFilter: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PHOTOS:
      return {
        ...state,
        arePhotosLoading: true,
        shouldReload: false,
      }
    case GET_MORE_PHOTOS:
      return {
        ...state,
        innerLoading: true,
      }
    case GET_MORE_PHOTOS + SUCCESS:
      return {
        ...state,
        innerLoading: false,
        photos: [...state.photos, ...action.photos],
      }
    case GET_MORE_PHOTOS + FAILURE:
      return {
        ...state,
        innerLoading: false,
      }
    case GET_PHOTOS + SUCCESS:
      return {
        ...state,
        arePhotosLoading: false,
        photos: action.photos,
        totalPages: action.totalPages,
      }
    case GET_PHOTOS + FAILURE:
      return {
        ...state,
        arePhotosLoading: false,
      }
    case GET_CATEGORIES + SUCCESS:
      return {
        ...state,
        arePhotosLoading: false,
        photosCategories: action.categories,
      }
    case GET_CATEGORIES + FAILURE:
      return {
        ...state,
        arePhotosLoading: false,
      }
    case ADD_PHOTO:
      return {
        ...state,
        isModalDisabled: true,
      }
    case EDIT_PHOTO:
      return {
        ...state,
        isModalDisabled: true,
      }
    case DELETE_PHOTO:
      return {
        ...state,
        isModalDisabled: true,
      }
    case ADD_CATEGORY:
      return {
        ...state,
        isModalDisabled: true,
      }
    case EDIT_CATEGORY:
      return {
        ...state,
        isModalDisabled: true,
      }
    case DELETE_CATEGORY:
      return {
        ...state,
        isModalDisabled: true,
      }
    case RESET_GALLERY_STATE:
      return {
        ...initialState,
      }
    case SET_GALLERY_FILTER:
      return {
        ...initialState,
        galleryFilter: action.payload,
      }
    case ACTION_SUCCESS:
      return {
        ...state,
        isModalDisabled: false,
        shouldReload: true,
      }
    case ACTION_FAILURE:
      return {
        ...state,
        isModalDisabled: false,
      }
    default:
      return state
  }
}

export const setGalleryFilter = action => ({
  type: SET_GALLERY_FILTER,
  payload: action,
})

export const getPhotos = action => ({
  type: GET_PHOTOS,
  payload: action,
})

export const getPhotosSuccess = ({ photos, totalPages }) => ({
  type: GET_PHOTOS + SUCCESS,
  photos,
  totalPages,
})

export const getPhotosFailure = () => ({
  type: GET_PHOTOS + FAILURE,
})

export const getMorePhotos = action => ({
  type: GET_MORE_PHOTOS,
  payload: action,
})

export const getMorePhotosSuccess = ({ photos, totalPages }) => ({
  type: GET_MORE_PHOTOS + SUCCESS,
  photos,
  totalPages,
})

export const getMorePhotosFailure = () => ({
  type: GET_MORE_PHOTOS + FAILURE,
})

export const addPhoto = action => ({
  type: ADD_PHOTO,
  payload: action,
})

export const editPhoto = action => ({
  type: EDIT_PHOTO,
  payload: action,
})

export const deletePhoto = action => ({
  type: DELETE_PHOTO,
  payload: action,
})

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES + SUCCESS,
  categories,
})
export const getCategoriesFailure = () => ({
  type: GET_CATEGORIES + FAILURE,
})

export const addCategory = action => ({
  type: ADD_CATEGORY,
  payload: action,
})

export const editCategory = action => ({
  type: EDIT_CATEGORY,
  payload: action,
})

export const deleteCategory = action => ({
  type: DELETE_CATEGORY,
  payload: action,
})

export const actionSuccess = () => ({
  type: ACTION_SUCCESS,
})

export const actionFailure = () => ({
  type: ACTION_FAILURE,
})

export const resetState = () => ({
  type: RESET_GALLERY_STATE,
})

function* fetchPhotos(action) {
  try {
    const [photos, categories] = yield all([
      PhotosGalleryService.getPhotos(action.payload),
      PhotosGalleryService.getCategories(),
    ])

    yield put(
      getPhotosSuccess({ photos: photos.data, totalPages: photos.pages })
    )
    yield put(getCategoriesSuccess(categories))
  } catch (err) {
    yield put(getPhotosFailure())
    yield put(getCategoriesFailure())
  }
}

function* fetchMorePhotos(action) {
  try {
    const [photos] = yield all([PhotosGalleryService.getPhotos(action.payload)])
    yield put(
      getMorePhotosSuccess({ photos: photos.data, totalPages: photos.pages })
    )
  } catch (err) {
    yield put(getMorePhotosFailure())
  }
}

function* addNewPhoto(action) {
  try {
    const res = yield PhotosGalleryService.addPhoto(action.payload)
    if (res.message === 'success') {
      notification.success({
        message: 'Added successfully',
      })
      yield put(actionSuccess())
      action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Something went wrong',
      })
      yield put(actionFailure())
    }
  } catch (err) {
    yield put(actionFailure())
  }
}

function* editingPhoto(action) {
  try {
    const res = yield PhotosGalleryService.editPhoto(action.payload)
    if (res.status === 200) {
      notification.success({
        message: 'Edited successfully',
      })
      yield put(actionSuccess())
      action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Something went wrong',
      })
      yield put(actionFailure())
    }
  } catch (err) {
    yield put(actionFailure())
  }
}

function* deletingPhoto(action) {
  try {
    const res = yield PhotosGalleryService.deletePhoto(action.payload)
    if (res.message === 'success') {
      notification.success({
        message: 'Deleted successfully',
      })
      yield put(actionSuccess())
      action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Something went wrong',
      })
      yield put(actionFailure())
    }
  } catch (err) {
    yield put(actionFailure())
  }
}

function* addingCategory(action) {
  try {
    const res = yield PhotosGalleryService.addCategory(action.payload)
    if (res.gallery_category) {
      yield put(actionSuccess())
      notification.success({
        message: 'Added successfully',
      })
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(actionFailure())
    }
  } catch (err) {
    yield put(actionFailure())
  }
}

function* editingCategory(action) {
  try {
    const res = yield PhotosGalleryService.editCategory(action.payload)
    if (res.status === 200) {
      yield put(actionSuccess())
      notification.success({
        message: 'Edited successfully',
      })
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(actionFailure())
    }
  } catch (err) {
    yield put(actionFailure())
  }
}

function* deletingCategory(action) {
  try {
    const res = yield PhotosGalleryService.deleteCategory(action.payload)
    if (res.status === 200) {
      yield put(actionSuccess())
      notification.success({
        message: 'Deleted successfully',
      })
      action.payload.setGalleryFilter('')
      return action.payload.toggleModal()
    } else {
      notification.error({
        message: 'Unexpected error',
        description: 'Something went wrong',
      })
      yield put(actionFailure())
    }
  } catch (err) {
    yield put(actionFailure())
  }
}

export function* GallerySagas() {
  yield all([
    takeLatest(GET_PHOTOS, fetchPhotos),
    takeLatest(GET_MORE_PHOTOS, fetchMorePhotos),
    takeLatest(ADD_PHOTO, addNewPhoto),
    takeLatest(EDIT_PHOTO, editingPhoto),
    takeLatest(DELETE_PHOTO, deletingPhoto),
    takeLatest(ADD_CATEGORY, addingCategory),
    takeLatest(EDIT_CATEGORY, editingCategory),
    takeLatest(DELETE_CATEGORY, deletingCategory),
  ])
}

export default reducer
