import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input, Select, Upload, Icon } from 'antd'

import { addVideo } from '../../../redux/videos/actions'

import 'antd/dist/antd.css'

const AddVideoModal = ({
  isModalShown,
  toggleModal,
  categories,
  form: { getFieldDecorator, validateFields },
  onAddVideo,
  isModalDisabled,
}) => {
  const [file, setFile] = useState(null)
  const [hasFile, setHasFile] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        onAddVideo({ values, toggleModal })
      }
    })
  }

  const handleChange = file => {
    if (file && file.fileList.length >= 1) {
      setFile(file.file)
      setHasFile(true)
      return null
    }
    setFile(null)
    setHasFile(false)
  }

  const parseCategories = () =>
    categories.map(category => {
      const { caption, _id } = category
      return (
        <Select.Option key={_id} value={_id}>
          {caption}
        </Select.Option>
      )
    })

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Add video</h2>
        <Form.Item label="Video URL">
          {getFieldDecorator('videoUrl', {
            rules: [
              { required: true, message: 'This field is required' },
              {
                pattern: /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/,
                message: 'Wrong pattern',
              },
              { min: 5, message: 'Minimum 2 symbols' },
            ],
          })(<Input size="default" type="text" placeholder="Video URL" />)}
        </Form.Item>
        <Form.Item label="Video caption">
          {getFieldDecorator('videoCaption', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 5, message: 'Minimum 2 symbols' },
            ],
          })(<Input size="default" type="text" placeholder="Video caption" />)}
        </Form.Item>
        <Form.Item label="Video category">
          {getFieldDecorator('videoCategory', {
            rules: [{ required: true, message: 'This field is required' }],
          })(
            <Select placeholder="Video category" size="default">
              {categories && parseCategories()}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Director">
          {getFieldDecorator('director')(
            <Input size="default" type="text" placeholder="Director" />
          )}
        </Form.Item>
        <Form.Item label="Dop">
          {getFieldDecorator('dop')(
            <Input size="default" type="text" placeholder="Dop" />
          )}
        </Form.Item>
        <Form.Item label="Production">
          {getFieldDecorator('production')(
            <Input size="default" type="text" placeholder="Production" />
          )}
        </Form.Item>
        <Form.Item label="Preview Image">
          {getFieldDecorator('previewImage')(
            <Upload
              beforeUpload={() => false}
              multiple={false}
              onChange={handleChange}
              accept=".png, .jpg"
            >
              <Button disabled={hasFile}>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  onAddVideo: action => dispatch(addVideo(action)),
})

AddVideoModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onAddVideo: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
}

export default connect(null, mapDispatchToProps)(Form.create()(AddVideoModal))
