import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  body {
    // transition: 0.3s;
    background-color: ${({ theme }) => theme.backgroundColor};
  }
  
  .navigation,
  ul.navigation-menus-mobile.menu-opened,
  .mobile-menu-wrapper.menu-opened {
    // transition: 0.3s;
    background-color: ${({ theme }) => theme.logoBackgroundColor};
  }

  .navigation.transparent {
    background-color: transparent;
  }

  .navigation .styled-delegated {
    color: ${({ theme }) => theme.textColor};
  }

  .navigation.colored {
    background-color: ${({ theme }) => theme.logoBackgroundColor};
  }
  
  .navigation li a, 
  .footer-nav ul li a,
  .navigation li {
    color: ${({ theme }) => theme.textColor};
  }

  .navigation li.logo a,
  .logo-mobile,
  .menu__btn span,
  .menu__btn span::before,
  .menu__btn span::after {
    transition: 0.3s;
    background-color: ${({ theme }) => theme.linkBackgroundColor};
  }

  .companyLogo,
  .follow,
  .cameraIcon {
    transition: 0.3s;
    fill: ${({ theme }) => theme.logoBackgroundColor};
  }

  .slide-caption,
  .slide-text {
    color: ${({ theme }) => theme.textColor};
  }

  .carousel-indicator {
    color: ${({ theme }) => theme.textColor};

    font-size: 20px;
  }

  .follow_us {
    background-color: ${({ theme }) => theme.linkBackgroundColor};
  }

  .follow-us-text {
    color: ${({ theme }) => theme.followUsText};
  }

  .nav-lamp,
  .follow.mobile {
    fill: ${({ theme }) => theme.lampColor};
    stroke: ${({ theme }) => theme.lampColor};
  }

  .videos-filters,
  .photos-filters {
    li.active {
      color: ${({ theme }) => theme.listColor} !important;
    }
  }

  .footer {
    transition: 0.3s;
    border-top: 3px solid ${({ theme }) => theme.footerBorderColor};
  }

  .load-more-block span,
  .show-more-block span {
    border: 2px solid ${({ theme }) => theme.textColor};

    color: ${({ theme }) => theme.textColor};
  }

  .companyLogo-reverse {
    transition: 0.3s;
    fill: ${({ theme }) => theme.reverseLogoColor};
  }

  .footer-general,
  .footer-pitch {
    p {
      a {
        transition: 0.3s;
        color: ${({ theme }) => theme.footerTextColor};
      }
    }
  }

  .follow-reverse {
    transition: 0.3s;
    fill: ${({ theme }) => theme.footerIconsReverse};
  }

  .team-description {
    p {
      transition: 0.3s;
      color: ${({ theme }) => theme.textColor}
    }
  }

  p.name, 
  p.speciality,
  p.title,
  p.title a,
  p.description,
  p.description a,
  .main-info h1, 
  .main-info p,
  .video-additional-description span,
  .add-new-title span,
  .no-videos,
  .no-photos,
  .new-team-member span, 
  .shooting-in-country p,
  .country-carousel-wrapper h1,
  .shooting-in-description-mobile p,
  .videos-carousel-wrapper span,
  .new-partner span {
    transition: 0.3s;
    color: ${({ theme }) => theme.textColor}
  }

  .new-video,
  .new-category,
  .new-team-member, 
  .new-photo,
  .sort-videos,
  .new-partner {
    border: 4px dashed ${({ theme }) => theme.textColor};

    .title {
      color: ${({ theme }) => theme.textColor};
    }
  }
  
  .no-team-members,
  .work-wrapper-title,
  span.edit-texts,
  .team-members-title {
    color: ${({ theme }) => theme.textColor};
  }

  .video-item-number > span,
  .video-item-name > span {
    color: ${({ theme }) => theme.textColor};
  }

  // .video-item-wrapper {
  //   border: 1px solid ${({ theme }) => theme.textColor};
  // }

  .btn {
    color: ${({ theme }) => theme.textColor};
    border: 3px solid ${({ theme }) => theme.textColor};
    transition: color 0.3s, border-color 0.3s;

    &:hover {
      color: #46bffc;
      border-color: #46bffc;
    }
  }

  .logoBG {
    fill:${({ theme }) => theme.footerTextColor};
  }

  .logoTEXT {
    fill:${({ theme }) => theme.backgroundColor};
  }

  .copyright {
    color: #8e8e8e;
    text-align: center;
  }
`

export default GlobalStyles
