import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import 'antd/dist/antd.css'

const ConfirmModal = ({
  isModalShown,
  toggleModal,
  isModalDisabled,
  onDelete,
  modalData,
  setVideoFilter = null,
  setGalleryFilter = null,
}) => {
  const onSubmit = event => {
    event.preventDefault()
    onDelete({ modalData, toggleModal, setVideoFilter, setGalleryFilter })
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <h2>Are you sure you want to delete this?</h2>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
  setVideoFilter: PropTypes.func,
  setGalleryFilter: PropTypes.func,
}

ConfirmModal.defaultProps = {
  setVideoFilter: null,
  setGalleryFilter: null,
}

export default ConfirmModal
