import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Icon,
  notification,
} from 'antd'

import { editVideo } from '../../../redux/videos/actions'

import 'antd/dist/antd.css'

const EditVideoModal = ({
  modalData: {
    url,
    caption,
    category,
    _id,
    production,
    image,
    director,
    pop: dop,
  },
  isModalShown,
  toggleModal,
  categories,
  form: { getFieldDecorator, validateFields },
  onEditVideo,
  isModalDisabled,
}) => {
  const [file, setFile] = useState(null)
  const [hasFile, setHasFile] = useState(false)
  const [isImageDeleted, setImageDeleted] = useState(false)
  const [isChangingImageProcess, setChangingImageProcess] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        if (file || (!isChangingImageProcess && !file)) {
          onEditVideo({
            values,
            _id,
            toggleModal,
            isChangingImageProcess,
            isImageDeleted,
          })
        } else {
          return notification.error({
            message: 'No file',
            description: `You've not attached any image`,
          })
        }
      }
    })
  }

  const handleChange = file => {
    if (file && file.fileList.length >= 1) {
      setFile(file.file)
      setHasFile(true)
      return null
    }
    setFile(null)
    setHasFile(false)
  }

  const clearProcess = () => {
    setImageDeleted(false)
    setChangingImageProcess(false)
  }

  const parseCategories = () =>
    categories.map(category => {
      const { caption, _id } = category
      return (
        <Select.Option key={_id} value={_id}>
          {caption}
        </Select.Option>
      )
    })

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Edit video</h2>
        <Form.Item label="Video URL">
          {getFieldDecorator('videoUrl', {
            rules: [
              { required: true, message: 'This field is required' },
              {
                pattern: /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/,
                message: 'Wrong pattern',
              },
              { min: 5, message: 'Minimum 2 symbols' },
            ],
            initialValue: url,
          })(<Input size="default" type="text" placeholder="Video URL" />)}
        </Form.Item>
        <Form.Item label="Video caption">
          {getFieldDecorator('videoCaption', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 5, message: 'Minimum 2 symbols' },
            ],
            initialValue: caption,
          })(<Input size="default" type="text" placeholder="Video caption" />)}
        </Form.Item>
        <Form.Item label="Video category">
          {getFieldDecorator('videoCategory', {
            rules: [{ required: true, message: 'This field is required' }],
            initialValue: (category && category._id) || 'Choose category',
          })(
            <Select placeholder="Video category" size="default">
              {categories && parseCategories()}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Director">
          {getFieldDecorator('director', {
            initialValue: director,
          })(<Input size="default" type="text" placeholder="Director" />)}
        </Form.Item>
        <Form.Item label="Dop">
          {getFieldDecorator('dop', {
            initialValue: dop,
          })(<Input size="default" type="text" placeholder="Dop" />)}
        </Form.Item>
        <Form.Item label="Production">
          {getFieldDecorator('production', {
            initialValue: production,
          })(<Input size="default" type="text" placeholder="Production" />)}
        </Form.Item>
        {!image && (
          <>
            <Form.Item label="Preview Image">
              {getFieldDecorator('previewImage')(
                <Upload
                  beforeUpload={() => false}
                  multiple={false}
                  onChange={handleChange}
                  accept=".png, .jpg"
                >
                  <Button disabled={hasFile}>
                    <Icon type="upload" /> Click to upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </>
        )}
        {image && !isImageDeleted && !isChangingImageProcess && (
          <>
            <a href={image} target="_blank" rel="noopener noreferrer">
              Image exists
            </a>
            <div>
              <button
                className="action-button-edit-modal"
                onClick={() => {
                  setImageDeleted(false)
                  setChangingImageProcess(true)
                }}
              >
                Change image
              </button>
              <button
                className="action-button-edit-modal"
                onClick={() => {
                  setChangingImageProcess(false)
                  setImageDeleted(true)
                }}
              >
                Delete image
              </button>
            </div>
          </>
        )}
        {isChangingImageProcess && !isImageDeleted && (
          <Form.Item label="Preview Image">
            {getFieldDecorator('previewImage')(
              <Upload
                beforeUpload={() => false}
                multiple={false}
                onChange={handleChange}
                accept=".png, .jpg"
              >
                <Button disabled={hasFile}>
                  <Icon type="upload" /> Click to upload
                </Button>
              </Upload>
            )}
            <button
              className="action-button-edit-modal"
              onClick={() => {
                setChangingImageProcess(false)
                setImageDeleted(true)
              }}
            >
              Delete image
            </button>
            <button
              className="action-button-edit-modal"
              onClick={() => clearProcess()}
            >
              Reset
            </button>
          </Form.Item>
        )}
        {isImageDeleted && !isChangingImageProcess && (
          <>
            <p style={{ margin: 0 }}>Image is deleted. Submit to apply</p>
            <button
              className="action-button-edit-modal"
              onClick={() => {
                setImageDeleted(false)
                setChangingImageProcess(true)
              }}
            >
              Change image
            </button>
            <button
              className="action-button-edit-modal"
              onClick={() => clearProcess()}
            >
              Reset
            </button>
          </>
        )}
      </Form>
    </Modal>
  )
}

EditVideoModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  onEditVideo: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
  modalData: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  onEditVideo: action => dispatch(editVideo(action)),
})

export default connect(null, mapDispatchToProps)(Form.create()(EditVideoModal))
