import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input, Upload, Icon } from 'antd'

import { TeamService } from '../../../services/api'
import { editTeamMember } from '../../../redux/team/actions'

import 'antd/dist/antd.css'

const EditTextsModal = ({
  isModalShown,
  toggleModal,
  form: { getFieldDecorator, validateFields },
  isModalDisabled,
  onEditTeamMember,
  modalData: { text, text_extra },
  getTexts,
}) => {
  const onSubmit = event => {
    event.preventDefault()
    validateFields(async (error, values) => {
      if (!error) {
        const res = await TeamService.updateTexts(values)

        if (res.status === 200) {
          toggleModal()
          getTexts()
        }
      }
    })
  }

  return (
    <Modal
      visible={isModalShown}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isModalDisabled}
          loading={isModalDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <h2>Edit texts</h2>
        <Form.Item label="Text">
          {getFieldDecorator('text', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 5, message: 'Minimum 5 symbols' },
            ],
            initialValue: text,
          })(<Input size="default" type="text" placeholder="Text" />)}
        </Form.Item>
        <Form.Item label="Extra Text">
          {getFieldDecorator('text_extra', {
            rules: [
              { required: true, message: 'This field is required' },
              { min: 5, message: 'Minimum 5 symbols' },
            ],
            initialValue: text_extra,
          })(<Input size="default" type="text" placeholder="Extra Text" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

EditTextsModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onEditTeamMember: PropTypes.func.isRequired,
  isModalDisabled: PropTypes.bool.isRequired,
  modalData: PropTypes.object.isRequired,
}

export default Form.create()(EditTextsModal)
