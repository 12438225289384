import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel'
import { Icon } from 'antd'
import { useMediaQuery } from 'beautiful-react-hooks'
import { HashLink } from 'react-router-hash-link'

import './ShootingIn.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import ukraine1 from '../../../style/source/images/ukraine/ukraine_photo_1.jpg'
import ukraine2 from '../../../style/source/images/ukraine/ukraine_photo_2.jpg'
import ukraine3 from '../../../style/source/images/ukraine/ukraine_photo_3.png'
import ukraine4 from '../../../style/source/images/ukraine/ukraine_photo_4.jpeg'
import ukraine5 from '../../../style/source/images/ukraine/ukraine_photo_5.jpg'
import ukraine6 from '../../../style/source/images/ukraine/ukraine_photo_6.jpg'
import ukraine7 from '../../../style/source/images/ukraine/ukraine_photo_7.jpg'
import ukraine8 from '../../../style/source/images/ukraine/ukraine_photo_8.jpg'
import ukraine9 from '../../../style/source/images/ukraine/ukraine_photo_9.jpg'
import ukraine10 from '../../../style/source/images/ukraine/ukraine_photo_10.jpg'
import ukraine11 from '../../../style/source/images/ukraine/ukraine_photo_11.jpg'
import ukraine12 from '../../../style/source/images/ukraine/ukraine_photo_12.jpg'
import ukraine13 from '../../../style/source/images/ukraine/ukraine_photo_13.jpg'
import ukraine14 from '../../../style/source/images/ukraine/ukraine_photo_14.jpg'
import ukraine15 from '../../../style/source/images/ukraine/ukraine_photo_15.jpg'
import ukraine16 from '../../../style/source/images/ukraine/ukraine_photo_16.jpg'
import ukraine17 from '../../../style/source/images/ukraine/ukraine_photo_17.jpg'
import ukraine18 from '../../../style/source/images/ukraine/ukraine_photo_18.jpg'
import ukraine19 from '../../../style/source/images/ukraine/ukraine_photo_19.jpg'
import ukraine20 from '../../../style/source/images/ukraine/ukraine_photo_20.jpg'
import ukraine21 from '../../../style/source/images/ukraine/ukraine_photo_21.jpg'
import ukraine22 from '../../../style/source/images/ukraine/ukraine_photo_22.jpg'
import ukraine23 from '../../../style/source/images/ukraine/ukraine_photo_23.jpg'

// import ukraine24 from '../../../style/source/images/ukraine/ukraine_photo_24.jpg'
// import ukraine25 from '../../../style/source/images/ukraine/ukraine_photo_25.JPG'
// import ukraine26 from '../../../style/source/images/ukraine/ukraine_photo_26.JPG'
// import ukraine27 from '../../../style/source/images/ukraine/ukraine_photo_27.png'
// import ukraine28 from '../../../style/source/images/ukraine/ukraine_photo_28.jpg'
// import ukraine29 from '../../../style/source/images/ukraine/ukraine_photo_29.JPG'
// import ukraine30 from '../../../style/source/images/ukraine/ukraine_photo_30.jpg'
// import ukraine31 from '../../../style/source/images/ukraine/ukraine_photo_31.jpg'
// import ukraine32 from '../../../style/source/images/ukraine/ukraine_photo_32.png'
// import ukraine33 from '../../../style/source/images/ukraine/ukraine_photo_33.jpg'
// import ukraine34 from '../../../style/source/images/ukraine/ukraine_photo_34.jpg'

import georgia1 from '../../../style/source/images/georgia/georgia_photo_1.jpg'
import georgia2 from '../../../style/source/images/georgia/georgia_photo_2.jpg'
import georgia3 from '../../../style/source/images/georgia/georgia_photo_3.jpg'
import georgia4 from '../../../style/source/images/georgia/georgia_photo_4.jpg'
import georgia5 from '../../../style/source/images/georgia/georgia_photo_5.jpg'
import georgia6 from '../../../style/source/images/georgia/georgia_photo_6.jpg'
import georgia7 from '../../../style/source/images/georgia/georgia_photo_7.jpg'
import georgia8 from '../../../style/source/images/georgia/georgia_photo_8.jpg'
import georgia9 from '../../../style/source/images/georgia/georgia_photo_9.jpg'
import georgia10 from '../../../style/source/images/georgia/georgia_photo_10.jpg'
import georgia11 from '../../../style/source/images/georgia/georgia_photo_11.jpg'
import georgia12 from '../../../style/source/images/georgia/georgia_photo_12.jpg'
import georgia13 from '../../../style/source/images/georgia/georgia_photo_13.jpg'
import georgia14 from '../../../style/source/images/georgia/georgia_photo_14.jpg'
import georgia15 from '../../../style/source/images/georgia/georgia_photo_15.jpg'
// import georgia16 from '../../../style/source/images/georgia/georgia_photo_16.jpg'
// import georgia17 from '../../../style/source/images/georgia/georgia_photo_17.jpg'

import kazakhstan1 from '../../../style/source/images/kazakhstan/kazakhstan_photo_1.jpg'
import kazakhstan2 from '../../../style/source/images/kazakhstan/kazakhstan_photo_2.jpg'
import kazakhstan3 from '../../../style/source/images/kazakhstan/kazakhstan_photo_3.jpg'
import kazakhstan4 from '../../../style/source/images/kazakhstan/kazakhstan_photo_4.jpg'
import kazakhstan5 from '../../../style/source/images/kazakhstan/kazakhstan_photo_5.jpg'
import kazakhstan6 from '../../../style/source/images/kazakhstan/kazakhstan_photo_6.jpg'
import kazakhstan7 from '../../../style/source/images/kazakhstan/kazakhstan_photo_7.jpg'
import kazakhstan8 from '../../../style/source/images/kazakhstan/kazakhstan_photo_8.jpg'
import kazakhstan9 from '../../../style/source/images/kazakhstan/kazakhstan_photo_9.jpg'
import kazakhstan10 from '../../../style/source/images/kazakhstan/kazakhstan_photo_10.jpg'
import kazakhstan11 from '../../../style/source/images/kazakhstan/kazakhstan_photo_11.jpg'
import kazakhstan12 from '../../../style/source/images/kazakhstan/kazakhstan_photo_12.jpg'
import kazakhstan13 from '../../../style/source/images/kazakhstan/kazakhstan_photo_13.jpg'
import kazakhstan14 from '../../../style/source/images/kazakhstan/kazakhstan_photo_14.jpg'
import kazakhstan15 from '../../../style/source/images/kazakhstan/kazakhstan_photo_15.jpg'
// import kazakhstan16 from '../../../style/source/images/kazakhstan/kazakhstan_photo_16.jpg'

import arrowToBottom from '../../../style/source/icons/arrowToBottom.svg'

import { setGalleryFilter } from '../../../redux/gallery/gallery'

const ShootingIn = ({ onSetGalleryFilter }) => {
  const countries = ['Ukraine', 'Kazakhstan', 'Georgia']
  const ukraineDescription = [
    'Ukraine is Europe’s largest country, stretching from Poland in the West to Russia in the East with access to the Black sea’s picturesque coast. It is accessible by only a short flight from all European capitals, has a visa-free entry and a great transport infrastructure. ',
    'For over 18 years Ukraine’s production companies and crews have been providing exceptional production service to clients from all over the world. From small film projects to big, Ukraine is a go-to country with a great variety of unique locations, fast permitting process, diverse talent pool and the most competitive production rates in the world. The biggest asset of Ukraine as a film production destination is the professionalism, experience and flexibility of the crews. ',
    'We are passionate to find new solutions for every challenge in film production and go an extra mile to deliver the highest level of production to our clients.',
  ]

  const georgiaDescription = [
    'Georgia has a wide range of beautiful locations. Geographically it is in a unique position to offer diverse landscapes and backdrops like modern cities, Black sea beaches, mountains, canyons, waterfalls, desert, etc. ',
    'The country sits in 7 climate zones from savannah to tundra and a good road infrastructure makes all the locations easily accessible. Georgia is a small country so it is possible to travel to several drastically different locations within 1 day. ',
    'One of the most valuable advantages of filming in Georgia are its tax rebates of 20-25% on both feature films and commercial film productions with budgets exceeding 100K euros.',
  ]

  const kazakhstanDescription = [
    'Kazakhstan is the 9th largest country in the world. Almaty is one of Kazakhstan’s largest cities and is the financial center of the country. It has all the advantages of a modern big city and it is surrounded by the mountain range. ',
    'Kazakhstan has rich nomadic culture which is influenced by its neighbours (China, Korea, Uzbekistan and Russia). ',
    'The country provides some of the most unique locations in Central Asia: vast plains, a desert, canyons looking like they could be on Mars, mountains with fresh water lakes, ancient ruins of central Asian temples, stunning winter sports facilities and an impressively large population of flamingos. ',
    'Seasonal inland climate with large temperature amplitudes provides comfortable conditions for exterior filming in any season. ',
    'Kazakhstan has all the necessary film production equipment and crew and has recently became more popular with foreign film crews and directors searching for impressive and unique locations for their films.',
  ]

  const ukraineImages = [
    ukraine1,
    ukraine2,
    ukraine3,
    ukraine4,
    ukraine5,
    ukraine6,
    ukraine7,
    ukraine8,
    ukraine9,
    ukraine10,
    ukraine11,
    ukraine12,
    ukraine13,
    ukraine14,
    ukraine15,
    ukraine16,
    ukraine17,
    ukraine18,
    ukraine19,
    ukraine20,
    ukraine21,
    ukraine22,
    ukraine23,
    // ukraine24,
    // ukraine25,
    // ukraine26,
    // ukraine27,
    // ukraine28,
    // ukraine29,
    // ukraine30,
    // ukraine31,
    // ukraine32,
    // ukraine33,
    // ukraine34,
  ]

  const georgiaImages = [
    georgia1,
    georgia2,
    georgia3,
    georgia4,
    georgia5,
    georgia6,
    georgia7,
    georgia8,
    georgia9,
    georgia10,
    georgia11,
    georgia12,
    georgia13,
    georgia14,
    georgia15,
    // georgia16,
    // georgia17,
  ]

  const kazakhstanImages = [
    kazakhstan1,
    kazakhstan2,
    kazakhstan3,
    kazakhstan4,
    kazakhstan5,
    kazakhstan6,
    kazakhstan7,
    kazakhstan8,
    kazakhstan9,
    kazakhstan10,
    kazakhstan11,
    kazakhstan12,
    kazakhstan13,
    kazakhstan14,
    kazakhstan15,
    // kazakhstan16,
  ]

  const body = document.getElementsByTagName('body')[0]

  const locationCountry = window.location.href.split('/').reverse()[0]
  const history = useHistory()
  const theme = localStorage.getItem('theme')

  const [className, setClass] = useState(null)
  const [description, setDescription] = useState([])
  const [images, setImages] = useState(null)
  const [choosedPhoto, setChoosedPhoto] = useState(null)
  const [isCarouselOpened, setCarouselState] = useState(false)

  const isMobile = useMediaQuery('(max-width: 991px)')

  const doesCountryExist = () =>
    countries.some(country => country === locationCountry)

  const renderCountries = () => {
    const newCountries = countries.filter(
      country => country !== locationCountry
    )

    return newCountries.map(country => (
      <NavLink key={country} to={`${country}`}>
        {country}
      </NavLink>
    ))
  }

  const remapPhotos = () => {
    if (!images) {
      return null
    }

    let indexToShift = null
    const shiftedValues = []
    const copyPhotos = images.map(image => image)

    images.map((image, index) => {
      if (image === choosedPhoto) {
        indexToShift = index
        return null
      }
      return null
    })

    for (let i = 0; i < images.length - indexToShift; i++) {
      shiftedValues.unshift(copyPhotos.pop())
    }

    const newArray = [...shiftedValues, ...copyPhotos]

    return newArray
  }

  const toggleMenu = () => {
    setCarouselState(!isCarouselOpened)
    // if (!isCarouselOpened) {
    //   body.style.overflow = 'hidden'
    // } else {
    //   body.style.overflow = 'unset'
    // }
  }

  const renderWideCarousel = () => {
    const remappedImages = remapPhotos()

    return remappedImages.map(image => {
      return (
        <div key={image} className="carousel-member">
          <div className="inner">
            <img
              className="unsetMaxHeight"
              style={{ maxHeight: 'unset' }}
              src={image}
              alt=""
            />
          </div>
        </div>
      )
    })
  }

  const renderDescription = () => description.map(p => <p key={p}>{p}</p>)

  const getDescription = () => {
    switch (locationCountry) {
      case 'Ukraine':
        return setDescription(ukraineDescription)
      case 'Georgia':
        return setDescription(georgiaDescription)
      case 'Kazakhstan':
        return setDescription(kazakhstanDescription)
    }
  }

  const getCountry = () => {
    switch (locationCountry) {
      case 'Ukraine':
        return setClass('ukraine')
      case 'Georgia':
        return setClass('georgia')
      case 'Kazakhstan':
        return setClass('kazakhstan')
    }
  }

  const getCountryImages = locationCountry => {
    switch (locationCountry) {
      case 'Ukraine':
        return setImages(ukraineImages)
      case 'Georgia':
        return setImages(georgiaImages)
      case 'Kazakhstan':
        return setImages(kazakhstanImages)
    }
  }

  const renderCarousel = () =>
    images.map(image => (
      <div
        onClick={() => {
          if (isMobile) {
            setChoosedPhoto(image)
            toggleMenu()
          }
        }}
        style={{ height: '100%', maxHeight: 'unset' }}
      >
        <img key={image} src={image} alt="" />
      </div>
    ))

  const seeMoreHandler = () => {
    history.push('/gallery')
    onSetGalleryFilter('5f199cf175f7d2001707c072')
  }

  const stopWatching = e => {
    e.stopPropagation()
    setChoosedPhoto(null)
    toggleMenu()
  }

  const findPos = obj => {
    let curtop = 0

    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop
      } while ((obj = obj.offsetParent))
      return [curtop - 70]
    }
  }

  useEffect(() => {
    getCountry()
    getDescription()
    getCountryImages(locationCountry)
  }, [locationCountry])

  useEffect(() => {
    if (!isMobile) {
      setCarouselState(false)
    }
  }, [isMobile])

  return (
    <div
      className={`shooting-in-wrapper${
        theme === 'dark' ? '' : ' black-arrows'
      }${choosedPhoto ? ' always-white' : ''}`}
    >
      {choosedPhoto && isMobile ? (
        <>
          <div className="team-members-carousel">
            <Carousel
              showThumbs={false}
              showIndicators={false}
              swipeable
              showStatus={false}
              infiniteLoop
            >
              {renderWideCarousel()}
            </Carousel>
            <Icon
              onClick={e => stopWatching(e)}
              className="stop"
              type="close"
            />
          </div>
        </>
      ) : (
        <>
          <div className="shooting-in">
            <div className={`image ${className}`}>
              <div
                className={`image-overlay ${
                  locationCountry === 'Georgia' ? 'bg-darken' : ''
                }`}
              />
            </div>
            {doesCountryExist() ? (
              <div className="container">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{ position: 'absolute', bottom: 30 }}
                    src={arrowToBottom}
                    alt="Arrow to bottom"
                    onClick={() =>
                      window.scroll(
                        0,
                        findPos(
                          document.getElementById(
                            `/${locationCountry}#explore-countries`
                          )
                        )
                      )
                    }
                  />
                  <div className="shooting-in-country">
                    <div className="left">
                      <p>shooting in</p>
                      <h1>{locationCountry}</h1>
                    </div>
                    <div className="countries">{renderCountries()}</div>
                  </div>
                  <div className="shooting-in-description">
                    {renderDescription()}
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="shooting-in-country">
                  <p>This country does not exist</p>
                </div>
              </div>
            )}
          </div>
          {doesCountryExist() ? (
            <>
              <div className="shooting-in-description-mobile">
                {renderDescription()}
              </div>
              <div
                className="country-carousel-wrapper"
                id={`/${locationCountry}#explore-countries`}
              >
                <h1>explore {locationCountry.toLowerCase()}</h1>
                {images ? (
                  <div className="carousel-wrapper">
                    <Carousel
                      showThumbs={false}
                      showIndicators
                      // autoPlay={!isModalShown}
                      stopOnHover
                      swipeable
                      showStatus={false}
                      infiniteLoop
                      interval={5000}
                      centerMode
                      renderIndicator={(_, isSelected, index) =>
                        isSelected ? (
                          <span className="carousel-indicator">
                            {index + 1}/{images.length}
                          </span>
                        ) : null
                      }
                    >
                      {renderCarousel()}
                    </Carousel>
                  </div>
                ) : null}
              </div>
              {locationCountry === 'Ukraine' && (
                <div className="show-more-block">
                  <span onClick={seeMoreHandler}>See more</span>
                </div>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  isUserLoggedIn: state.user.isUserLoggedIn,
  arePhotosLoading: state.gallery.arePhotosLoading,
  isModalDisabled: state.gallery.isModalDisabled,
  photosCategories: state.gallery.photosCategories,
  shouldReload: state.gallery.shouldReload,
  photos: state.gallery.photos,
  totalPages: state.gallery.totalPages,
  innerLoading: state.gallery.innerLoading,
  galleryFilter: state.gallery.galleryFilter,
})

const mapDispatchToProps = dispatch => ({
  onSetGalleryFilter: action => dispatch(setGalleryFilter(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShootingIn)
